import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "../store";

Vue.use(VueRouter);

const AuthGuard = (to, from, next) => {
  store.dispatch("tryAutoLogin").then().catch()
  const isConnected = store.getters.getToken;
  if (isConnected) {
    next();
  } else {
    next("/");
  }
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/sidenav",
    component: () => import("../components/headers/Sidenav.vue"),
  },
  {
    path: "/oeuvre/:id",
    component: () => import("../views/Artwork.vue"),
  },
  {
    path: "/rechercher",
    component: () => import("../views/Search.vue"),
  },
  {
    path: "/resultats",
    component: () => import("../views/Results.vue"),
  },
  {
    path: "/oeuvres",
    component: () => import("../views/ArtworksList.vue"),
  },
  {
    path: "/artistes",
    component: () => import("../views/ArtistsList.vue"),
  },
  {
    path: "/filter-par-prix",
    component: () => import("../views/ArtworksByPrice.vue"),
  },
  {
    path: "/commander",
    component: () => import("../views/Checkout.vue"),
  },
  {
    path: "/panier",
    component: () => import("../views/Cart.vue"),
  },
  {
    path: "/mes-achats",
    component: () => import("../views/Purchases.vue"),
  },
  {
    path: "/commande-reussie",
    component: () => import("../views/OrderSuccess.vue"),
  },
  {
    path: "/artiste/:id",
    component: () => import("../views/Artist.vue"),
  },
  {
    path: "/favoris/oeuvres",
    component: () => import("../views/FavoriteArtworks.vue"),
  },
  {
    path: "/favoris/artistes",
    component: () => import("../views/FavoriteArtists.vue"),
  },
  {
    path: "/mon-compte",
    component: () => import("../views/account/Account.vue"),
    beforeEnter: AuthGuard,
  },

  // About
  {
    path: "/a-propos",
    component: () => import("../views/about/About.vue"),
  },
  {
    path: "/aide",
    component: () => import("../views/about/Help.vue"),
  },
  {
    path: "/nous-contacter",
    component: () => import("../views/about/Contact.vue"),
  },
  {
    path: "/comment-ca-marche",
    component: () => import("../views/about/HowItWorks.vue"),
  },
  {
    path: "/mentions-legales",
    component: () => import("../views/about/LegalMentions.vue"),
  },
  {
    path: "/conditions-generales-de-ventes",
    component: () => import("../views/about/TermsOfSell.vue"),
  },
  {
    path: "/conditions-generales-d-utilisation",
    component: () => import("../views/about/TermsOfUse.vue"),
  },
  //
  {
    path: "/dashboard",
    component: () => import("../views/dashboard/Dashboard.vue"),
    beforeEnter: AuthGuard,
  },
  {
    path: "/dashboard/ajouter",
    component: () => import("../views/dashboard/AddArtwork.vue"),
    beforeEnter: AuthGuard,
  },
  {
    path: "/dashboard/modifier/:id",
    component: () => import("../views/dashboard/EditArtwork.vue"),
    beforeEnter: AuthGuard,
  },
  {
    path: "/dashboard/profil",
    component: () => import("../views/dashboard/Profile.vue"),
    beforeEnter: AuthGuard,
  },
  {
    path: "/dashboard/commandes",
    component: () => import("../views/dashboard/Orders.vue"),
    beforeEnter: AuthGuard,
  },
  {
    path: "/404",
    component: () => import("../views/404.vue"),
  },
  {
    path: "/:pathMatch(.*)",
    component: () => import("../views/404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;
