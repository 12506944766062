<template>
  <div class="card rounded-0 mb-7">
    <div class="artists_follow_card-full">
      <router-link class="artists_follow_img_link" :to="`/artiste/${artist.user_id}`" :title="`Voir page de ${fullname}`">
        <div :style="`background-image:url(${artist.data_picture ? artist.data_picture.src : getEmptyUserPicture})`" class="artists_follow_img"></div>
      </router-link>
      <div class="mt-1 mb-3">
        <router-link :to="`/artiste/${artist.slug}`" class="bold text-uppercase m-0">{{ fullname }}</router-link>
        <div class="artist-cell-item__categories" v-if="artist.categories_ids && artist.categories_ids.length">
          <span v-for="(category, i) in artist.categories_ids" :key="i">{{ category.name }}</span>
        </div>
        <div class="artist-cell-item__followers" v-if="artist.num_artworks">
          {{ artist.num_artworks || 0}} oeuvre{{ artist.num_artworks > 0 ? 's': ''}}
        </div>
      </div>
      <button class="btn btn-follow black btn-block text-uppercase py-0 _flex _center">
        <i class="icon-follow mr-1"></i> Suivre
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  props: {
    artist: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapGetters([
        "getEmptyArtworkPicture",
        "getEmptyUserPicture",
    ]),
    fullname() {
      return `${this.artist.last_name || ""} ${this.artist.middle_name || ""} ${this.artist.first_name || ""}`;
    },
  },
  methods: {
    ...mapMutations([]),
    ...mapActions([]),
  }
};
</script>

<style></style>
