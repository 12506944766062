<template>
  <div class="d-flex w-100 flex-column align-items-center my-20">
    <img src="../../assets/images/no_result_search.svg" alt="" style="height:180px;width:180px;object-fit:contain"/>
    <br />
    <h4 style="max-width:400px;text-align:center;font-weight:600">Aucune oeuvre ne correspond à votre recherche</h4>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
