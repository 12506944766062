import { supabase } from "../../lib/supabase";
import { storage } from "../../lib/firebase";
import router from "../../router";
import slugify from "slugify";
import { handleUserNotAuth, handleError } from "../errors";

const ARTISTS_DB = "artists";

const artistQuery = `
  *,
  country ( * )
`;

function toLower(arg) {
  return arg.toLowerCase().toString();
}

const state = {
  currentArtist: null,
  artists: {},
};

const getters = {
  currentArtist: (state) => state.currentArtist,
  artists: (state) => state.artists,
};

const mutations = {
  SET_CURRENT_ARTIST(state, payload) {
    return (state.currentArtist = payload);
  },
  SET_ARTISTS(state, payload) {
    return (state.artists = payload);
  },
};

const actions = {
  async findArtists({ commit }, payload) {
    const { data, count, error } = await supabase
      .from(ARTISTS_DB)
      .select(artistQuery, { count: "exact" })
      .textSearch("firstname", payload, { config: "french" })
      .textSearch("lastname", payload, { config: "french" })
      .textSearch("middlename", payload, { config: "french" })
      .match({ status: "published" })
      .order("created_at", { ascendidng: false });

    if (error) {
      handleError();
    }

    commit("SET_ARTISTS", { data, count });
  },
  async fetchArtists({ dispatch }, payload) {
    if (payload && payload.trim().length) {
      dispatch("fetchArtistsWithFilters", payload);
    } else {
      dispatch("fetchArtistsNoFilters");
    }
  },
  async fetchCurrentArtist({ commit }, slug) {
    const { data, error } = await supabase
      .from(ARTISTS_DB)
      .select(
        `
          *,
          country ( * ),
          artworks ( * )
        `
      )
      .match({ slug, status: "published" })
      .single();

    if (error) {
      handleError();
    }

    if (!data) {
      return router.push("/404");
    }

    commit("SET_CURRENT_ARTIST", data);
  },
  async fetchArtistsWithFilters({ commit }, payload) {
    const { data, error, count } = await supabase
      .from(ARTISTS_DB)
      .select(artistQuery, { count: "exact" })
      .contains("categories", [payload])
      .match({ status: "published" })
      .order("created_at", { ascendidng: false })
      .range(0, 20);

    if (error) {
      handleError();
    }

    commit("SET_ARTISTS", { data, count });
  },
  async fetchArtistsNoFilters({ commit }) {
    const { data, error, count } = await supabase
      .from(ARTISTS_DB)
      .select(artistQuery, { count: "exact" })
      .order("created_at", { ascendidng: false })
      .range(0, 20);

    if (error) {
      handleError();
    }

    commit("SET_ARTISTS", { data, count });
  },
  async addArtist({ commit, getters }, payload) {
    const isLoggedIn = getters.isLoggedIn;

    if (!isLoggedIn) handleUserNotAuth(commit);
    
    const user_id = getters.user_id;

    const {
      email,
      firstname,
      lastname,
      middlename,
      address,
      city,
      country,
      phone_number,
      image,
      year_birth,
      description,
      categories,
      tags,
    } = payload;

    if (!getters.user.is_artist) {
      return alert("Vous n'êtes pas autorisé");
    }

    const fullname = `${toLower(payload.firstname)} ${toLower(payload.lastname)}`;

    const slug = slugify(fullname, { lower: true });

    let imageResponse;

    const newArtist = {
      id: user_id,
      firstname,
      lastname,
      middlename,
      year_birth: Number(year_birth),
      email,
      phone_number,
      description,
      categories,
      tags,
      address,
      city,
      country: Number(country),
      slug,
      status: "pending",
    };

    if (image) {
      const filename = image.name;
      const ext = filename.slice(filename.lastIndexOf("."));
      let uploadTask = storage.ref(`artists/${user_id}.png`).put(image);

      imageResponse = await new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          () => {}, // snapshot
          () => {}, // error
          () => {
            return uploadTask.snapshot.ref
              .getDownloadURL()
              .then((downloadURL) => resolve(downloadURL))
              .catch((e) => reject(e));
          }
        );
      });

      if (imageResponse) {
        newArtist.avatar_url = imageResponse;
      }
    }

    // return console.log(newArtist);

    const { data, error } = await supabase.from("artists").insert(newArtist);

    if (error) {
      console.log(error);
      alert("Une erreur s'est produite. Veuillez réessayer plus tard");
      return { success: false };
    }

    console.log(data);

    return { success: true, data };
  },
  async updateArtist({ commit, getters }, payload) {
    const isLoggedIn = getters.isLoggedIn;
    if (!isLoggedIn) handleUserNotAuth(commit);
    
    const user_id = getters.user_id;

    console.log("User id");
    console.log(user_id);

    if (!getters.user.is_artist) {
      return alert("Vous n'êtes pas autorisé");
    }

    let imageResponse;

    const id = payload.id;
    let data = {};

    // Artwork Data
    if (payload.firstname) data.firstname = payload.firstname;
    if (payload.lastname) data.lastname = payload.lastname;
    if (payload.middlename) data.middlename = payload.middlename;
    if (payload.year_birth) data.year_birth = Number(payload.year_birth);
    if (payload.email) data.email = payload.email;
    if (payload.phone_number) data.phone_number = Number(payload.phone_number);
    if (payload.description) data.description = payload.description;
    if (payload.categories) data.categories = payload.categories;
    if (payload.tags) data.tags = payload.tags;
    if (payload.address) data.address = payload.address;
    if (payload.city) data.city = payload.city;
    if (payload.country) data.country = Number(payload.country);

    const { data: artist, error: artistError } = await supabase
      .from(ARTISTS_DB)
      .select()
      .match({ id: user_id });

    if (artistError) {
      handleError();
      // throw Error(error);
    }

    if (!artist) {
      handleError();
      return { success: false, message: "L'artiste n'existe plus." };
    }

    if (payload.image) {
      const filename = payload.image.name;
      // const ext = filename.slice(filename.lastIndexOf("."));
      let uploadTask = storage.ref(`artists/${user_id}.png`).put(payload.image);

      imageResponse = await new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          () => {}, // snapshot
          () => {}, // error
          () => {
            return uploadTask.snapshot.ref
              .getDownloadURL()
              .then((downloadURL) => resolve(downloadURL))
              .catch((e) => reject(e));
          }
        );
      });

      if (imageResponse) {
        data.avatar_url = imageResponse;
      }
    }

    const { data: updated, error } = await supabase
      .from(ARTISTS_DB)
      .update(data)
      .match({ id: user_id });

    if (error) {
      console.log(error);
      console.log("Update error");
      handleError();
    }

    return { success: true, updated };
  },
  async deleteArtist({ commit, getters }, id) {
    const isLoggedIn = getters.isLoggedIn;
    if (!isLoggedIn) handleUserNotAuth(commit);
    
    const user_id = getters.user_id;

    if (!getters.user.is_artist) {
      return alert("Vous n'êtes pas autorisé");
    }

    const { data, error } = await supabase
      .from("artists")
      .delete()
      .match({ id, user_id });

    if (error) {
      handleError();
    }

    console.log(data);

    return { success: true, data };
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
