import { supabase } from "../../lib/supabase";
import { storage } from "../../lib/firebase";
import router from "../../router";
import slugify from "slugify";
import Fuse from "fuse.js";
import { handleUserNotAuth, handleError } from "../errors";

async function uploadSingleImage(id, image) {
  if (!image) return;

  const filename = image.name;
  const ext = filename.slice(filename.lastIndexOf("."));
  let uploadTask = storage.ref(`artworks/${id}/${filename}${ext}`).put(image);

  var downloadURL = await new Promise((resolve, reject) => {
    uploadTask.on(
      "state_changed",
      () => {}, // snapshot
      () => {}, // error
      () => {
        return uploadTask.snapshot.ref
          .getDownloadURL()
          .then(function(downloadURL) {
            resolve(downloadURL);
          })
          .catch((e) => reject(e));
      }
    );
  });

  return downloadURL;
}

async function uploadMultiImages(id, images) {
  console.log("images on upload multiple", images);
  const imageUrls = [];

  for (const image of images) {
    let imageUrl = new Promise((resolve, reject) => {
      const filename = image.name;
      const ext = filename.slice(filename.lastIndexOf("."));
      let uploadTask = storage
        .ref(`artworks/${id}/${filename}${ext}`)
        .put(image);

      var downloadURL = new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          () => {}, // snapshot
          () => {}, // error
          () => {
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then((downloadURL) => {
                resolve(downloadURL);
              })
              .catch((e) => reject(e));
          }
        );
      });

      resolve(downloadURL);
    });

    imageUrls.push(imageUrl);
  }

  const snapshots = await Promise.all(imageUrls);

  const transformedImageUrls = snapshots.map((data) => {
    return data;
  });

  return transformedImageUrls;
}

const ARTWORKS_DB = "artworks";
const artworkQuery = `
  *,
  artist:artist_id ( * )
`;

function toLower(arg) {
  return arg.toLowerCase().toString();
}

const state = {
  currentArtwork: null,
  artworks: {},
  artist_artworks: [],
  dashboard_artworks: [],
};

const getters = {
  currentArtwork: (state) => state.currentArtwork,
  artworks: (state) => state.artworks,
  artist_artworks: (state) => state.artist_artworks,
  dashboard_artworks: (state) => state.dashboard_artworks,
};

const mutations = {
  SET_CURRENT_ARTWORK(state, payload) {
    return (state.currentArtwork = payload);
  },
  SET_ARTWORKS(state, payload) {
    return (state.artworks = payload);
  },
  SET_ARTIST_ARTWORKS(state, payload) {
    return (state.artist_artworks = payload);
  },
  SET_DASHBOARD_ARTWORKS(state, payload) {
    return (state.dashboard_artworks = payload);
  },
};

const actions = {
  async searchArtworks({ commit }, payload) {
    const { data, error } = await supabase
      .from("artworks")
      .select(artworkQuery)
      .match({ status: "published" })
      .order("created_at", {
        ascendidng: false,
      });

    if (error) {
      handleError();
    }

    console.log("Fetch data: ", data);

    // Fuse
    // Setup
    const options = {
      isCaseSensitive: true,
      // includeScore: true,
      shouldSort: true,
      // includeMatches: false,
      findAllMatches: true,
      // minMatchCharLength: 1,
      // location: 0,
      // threshold: 0.6,
      // distance: 100,
      useExtendedSearch: true,
      keys: ["name", "description", "year", "category"],
    };

    const fuse = new Fuse(data, options);

    // Search
    const searchResults = await fuse.search(payload);

    console.log(searchResults);

    commit("SET_ARTWORKS", {
      data: searchResults.map((search) => search.item),
      count: searchResults.length,
    });
  },
  async fetchArtworks({ dispatch }, payload) {
    if (payload) {
      dispatch("fetchArworksWithFilters", payload);
    } else {
      dispatch("fetchArtworksNoFilters");
    }
  },
  async fetchCurrentArtwork({ commit }, slug) {
    const { data, error } = await supabase
      .from(ARTWORKS_DB)
      .select(artworkQuery)
      .match({ slug, status: "published" })
      .single();

    if (error) {
      handleError();
    }

    if (!data) {
      return router.push("/404");
    }

    commit("SET_CURRENT_ARTWORK", data);
  },
  async fetchArworksWithFilters({ commit }, category) {
    console.log("category: ");
    console.log(category);

    const { data, error, count } = await supabase
      .from(ARTWORKS_DB)
      .select(artworkQuery, { count: "exact" })
      .match({ category, status: "published" })
      .order("created_at", { ascendidng: false })
      .range(0, 20);

    if (error) {
      handleError();
    }

    console.log("With filters: ");
    console.log(data);

    commit("SET_ARTWORKS", { data, count });
  },
  async fetchArtworksNoFilters({ commit }) {
    console.log("with no filters");
    const { data, error, count } = await supabase
      .from(ARTWORKS_DB)
      .select(artworkQuery, { count: "exact" })
      .match({ status: "published" })
      .order("created_at", { ascendidng: false })
      .range(0, 20);

    if (error) {
      handleError();
    }

    commit("SET_ARTWORKS", { data, count });
  },
  async fetchArtworksByPrice({ commit }, payload) {
    let response;

    if (payload == "<500") {
      response = await supabase
        .from(ARTWORKS_DB)
        .select(artworkQuery, { count: "exact" })
        .lt("price", 500)
        .match({ status: "published" })
        .order("created_at", { ascendidng: false });
    } else if (payload == "500-5000") {
      // response = await ref.gte("price", 500).lt("price", 5000);
      response = await supabase
        .from(ARTWORKS_DB)
        .select(artworkQuery, { count: "exact" })
        // .rangeGte("price", [500, 5000])
        .gte("price", 500)
        .lt("price", 5000)
        .match({ status: "published" })
        .order("created_at", { ascendidng: false });
    } else if (payload == "5000-15000") {
      // response = await ref.gte("price", 5000).lt("price", 15000);
      response = await supabase
        .from(ARTWORKS_DB)
        .select(artworkQuery, { count: "exact" })
        // .rangeGte("price", [5000, 15000])
        .gte("price", 5000)
        .lt("price", 15000)
        .match({ status: "published" })
        .order("created_at", { ascendidng: false });
    } else if (payload == "15000-50000") {
      // response = await ref.gte("price", 15000).lt("price", 50000);
      response = await supabase
        .from(ARTWORKS_DB)
        .select(artworkQuery, { count: "exact" })
        // .rangeGt("price", [15000, 50000])
        .gte("price", 15000)
        .lt("price", 50000)
        .match({ status: "published" })
        .order("created_at", { ascendidng: false });
    } else if (payload == "50000-100000") {
      // response = await ref.gte("price", 50000).lt("price", 100000);

      response = await supabase
        .from(ARTWORKS_DB)
        .select(artworkQuery, { count: "exact" })
        // .rangeGt("price", [50000, 100000])
        .gte("price", 50000)
        .lt("price", 100000)
        .match({ status: "published" })
        .order("created_at", { ascendidng: false });
    } else if (payload == ">100000") {
      response = await supabase
        .from(ARTWORKS_DB)
        .select(artworkQuery, { count: "exact" })
        .gt("price", 100000)
        .match({ status: "published" })
        .order("created_at", { ascendidng: false });
    }

    const { data, error, count } = response;

    console.log("by price filter");
    console.log(data);

    if (error) {
      handleError();
    }

    commit("SET_ARTWORKS", { data, count });
  },
  async fetchArtistArworks({ commit }, artist_id) {
    const { data, error } = await supabase
      .from(ARTWORKS_DB)
      .select()
      .match({ artist_id, status: "published" })
      .order("id", { ascendidng: false });

    if (error) {
      console.log(error);
    }

    commit("SET_ARTIST_ARTWORKS", data);
  },
  async dashboardArtworks({ commit, getters }) {
    const isLoggedIn = getters.isLoggedIn;

    if (!isLoggedIn) handleUserNotAuth();

    const user_id = getters.user_id;

    const { data, error, count } = await supabase
      .from("artworks")
      .select("*", { count: "exact" })
      .match({ artist_id: user_id })
      .order("created_at", { ascending: false });

    if (error) {
      return alert("Une erreur s'est produite. Veuillez réessayer ultériement");
    }

    commit("SET_DASHBOARD_ARTWORKS", { count, artworks: data });
  },
  async addArtwork({ commit, getters }, payload) {
    const isLoggedIn = getters.isLoggedIn;

    if (!isLoggedIn) handleUserNotAuth();

    const user_id = getters.user_id;

    if (!getters.user.is_artist) {
      return alert("Vous n'êtes pas autorisé");
    }
    const artist = getters.profile;

    const slug = slugify(toLower(payload.name), { lower: true });

    const newArtwork = {
      slug: `${slug}-${artist.slug}`,
      artist_id: user_id,
      name: payload.name || "",
      year: +payload.year || null,
      category: +payload.category || "",
      price: Number(payload.price) || null,
      currency: payload.currency || "usd",
      solde_price: Number(payload.solde_price) || null,
      item_availables: Number(payload.item_availables) || null,
      item_totals: Number(payload.item_totals) || null,
      weight: payload.weight || "",
      description: payload.description || "",
      signature: payload.signature || "",
      authentique: payload.authentique || "",
      dimensions: payload.dimensions || "",
      encadrement: payload.encadrement || "",
      tirage: payload.tirage || "",
      delivery: payload.delivery || "",
      status: payload.status || "draft",
    };

    const { data, error } = await supabase
      .from(ARTWORKS_DB)
      .insert([newArtwork]);

    if (error) {
      handleError();
    }

    // const response = await supabase
    //   .from("artists")
    //   .update({ artworks: [...artist.artworks, data.id] })
    //   .match({ id: user_id });

    // if (response.error) {
    //   console.log("Error updating user's artworks");
    //   handleError();
    // }

    let key = data[0].id;
    let res;
    let image_url;
    let images = [];

    console.log(payload.images);

    if (payload.image) {
      image_url = await uploadSingleImage(key, payload.image);
      res = await supabase
        .from("artworks")
        .update({ image_url })
        .match({ id: key });
    }
    if (payload.images.length > 0) {
      images = await uploadMultiImages(key, payload.images);
      res = await supabase
        .from("artworks")
        .update({ images })
        .match({ id: key });
    }

    if (res.error) {
      handleError();
    }

    return { success: true, data: { ...data, image_url, images } };
  },
  async updateArtwork({ commit, getters }, payload) {
    const isLoggedIn = getters.isLoggedIn;
    if (!isLoggedIn) handleUserNotAuth();

    const user_id = getters.user_id;

    const id = payload.id;
    const artist_id = payload.artist_id;

    if (!getters.user.is_artist || artist_id !== user_id) {
      return alert("Vous n'êtes pas autorisé");
    }

    let data = {};

    // Artwork Data
    if (payload.name) data.name = payload.name;
    if (payload.year) data.year = +payload.year;
    if (payload.category) data.category = payload.category;
    if (payload.price) data.price = Number(payload.price);
    if (payload.currency) data.currency = payload.currency;
    if (payload.solde_price) data.solde_price = Number(payload.solde_price);
    if (payload.item_availables)
      data.item_availables = Number(payload.item_availables);
    if (payload.item_totals) data.item_totals = Number(payload.item_totals);
    if (payload.weight) data.weight = payload.weight;
    if (payload.description) data.description = payload.description;
    if (payload.signature) data.signature = payload.signature;
    if (payload.authentique) data.authentique = payload.authentique;
    if (payload.dimensions) data.dimensions = payload.dimensions;
    if (payload.encadrement) data.encadrement = payload.encadrement;
    if (payload.tirage) data.tirage = payload.tirage;
    if (payload.delivery) data.delivery = payload.delivery;
    if (payload.status) data.status = payload.status;

    const { data: artwork, error: artworkError } = await supabase
      .from(ARTWORKS_DB)
      .select()
      .match({ id, artist_id });

    if (artworkError) {
      handleError();
    }

    if (!artwork) {
      return { success: false, message: "L'oeuvre n'existe plus." };
    }

    const { data: updated, error } = await supabase
      .from(ARTWORKS_DB)
      .update(data)
      .match({ id, artist_id });

    if (error) {
      handleError();
    }

    return { success: true, updated };
  },
  async deleteArtwork({ commit, getters }, payload) {
    const isLoggedIn = getters.isLoggedIn;
    if (!isLoggedIn) handleUserNotAuth();

    const user_id = getters.user_id;

    if (!getters.user.is_artist) {
      return alert("Vous n'êtes pas autorisé");
    }

    const id = payload.id;
    const artist_id = payload.artist_id;

    const { data, error } = await supabase
      .from(ARTWORKS_DB)
      .delete()
      .match({ id, artist_id });

    const artist = getters.artist;

    const artworks = artist.artworks.filter((id) => id !== id);

    const response = await supabase
      .from("artists")
      .update({ artworks })
      .match({ id: user_id });

    if (error) {
      handleError();
    }

    console.log(data);

    return { success: true, data };
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
