export function handleError() {
  alert("Une erreur s'est produite. Veuillez réessayer plus tard");
  return { success: false };
}

export function handleAuthError(error) {
  switch (error.code) {
    case "auth/user-not-found":
      alert(
        "Il n'y a pas d'utilisateur correspondant à cette adresse email. Veuillez réessayer..."
      );
      return { success: false };
    case "auth/wrong-password":
      alert("Le mot de passe est invalide. Veuillez réessayer...");
      return { success: false };
    case "auth/invalid-email":
      alert("L'adresse email n'est pas valide. Veuillez réessayer...");
      return { success: false };
    case "auth/network-request-failed":
      alert("Vérifiez votre connexion internet et réessayer plus tard...");
      return { success: false };
    default:
      alert(error.message);
      return { success: false };
  }
}

export function handleSignError(error) {
  switch (error.code) {
    case "auth/weak-password":
      alert(
        "Le mot de passe doit avoir au-moins 6 charactères. Veuillez réessayer..."
      );
      return { success: false };
    case "auth/email-already-in-use":
      alert(
        "L'adresse email existe déjà sur un autre compte. Veuillez réessayer..."
      );
      return { success: false };
    case "auth/invalid-email":
      alert("L'adresse email n'est pas valide. Veuillez réessayer...");
      return { success: false };
    case "auth/network-request-failed":
      alert("Vérifiez votre connexion internet et réessayer plus tard...");
      return { success: false };
    default:
      alert(error.message);
      return { success: false };
  }
}

export function handleUserNotAuth() {
  alert("Veuillez-vous connecter à votre compte");
  return { success: false };
}

export function handleUserAlreadyAuth() {
  alert("Vous êtes déjà connecté à votre compte");
  return { success: false };
}

export function handleErrorArg(arg) {
  alert(arg);
  return { success: false };
}
