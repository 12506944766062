<template>
  <div style="user-select:none">
    <router-view />

    <!-- SIGN -->
    <sign-modal />
    <logout-modal />
    <not-connected-modal />

    <!-- Snackbar to show alert -->
    <snackbar />
  </div>
</template>

<script>
import firebase from "firebase";

export default {
  mounted() {
    const { commit, dispatch, getters } = this.$store;

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        dispatch("autoSignIn", user.uid);
        commit("SET_IS_LOGGED_IN", true);
        //
        dispatch("fetchProfile").then(() => {
          if (getters.user?.is_artist) {
            dispatch("fetchMyProfileArtist");
          }
        });
        //
        dispatch("fetchFavoriteArtists");
        dispatch("fetchFavoriteArtworks");
        dispatch("fetchCart");
        // dispatch("fetchCart");
      } else {
        // dispatch("logout");
        commit("SET_USER", null);
        commit("SET_IS_LOGGED_IN", false);
        commit("SET_FAVORITE_ARTWORKS", []);
        commit("SET_FAVORITE_ARTWORKS_COUNT", 0);
        commit("SET_FAVORITE_ARTISTS", []);
        commit("SET_FAVORITE_ARTISTS_COUNT", 0);
        commit("SET_CART", []);
        commit("SET_CART_COUNT", 0);
        commit("SET_ORDERS", []);
        commit("SET_ORDERS_COUNT", 0);
        commit("SET_MY_PROFILE_ARTIST", null);
      }
    });
  },
  created() {
    const { dispatch } = this.$store;

    dispatch("fetchCountries");
    dispatch("fetchCategories");
  },
};
</script>
