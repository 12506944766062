<template>
  <div>
    <section class="bg-lighter">
      <div class="container pt-20 pb-10" id="howto">
        <div class="d-flex flex-column align-items-center text-center">
          <h4>Comment ça marche?</h4>
          <div class="row mt-10 mb-2">
            <div class="col-12 col-md-4">
              <i class="icon-search"></i>
              <h6 class="mt-3">Sélection</h6>
              <p class="">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
              </p>
            </div>
            <div class="col-12 col-md-4">
              <i class="icon-screen"></i>
              <h6 class="mt-3">Mise en ligne</h6>
              <p class="">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
              </p>
            </div>
            <div class="col-12 col-md-4">
              <i class="icon-pack"></i>
              <h6 class="mt-3">Commande</h6>
              <p class="">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
              </p>
            </div>
          </div>
          <a href="javascript:void(0);" class="animated-underline">En savoir +</a>
        </div>
      </div>
    </section>

    <!-- Footer -->

    <footer class="bg-black">
      <div class="container pt-10 pb-5">
        <!--  -->
        <div class="row pt-3">
          <div class="col-6 col-lg-3 mb-6">
            <ul class="list-unstyled ">
              <li class="list-element">
                <router-link to="/a-propos" class="animated-underline"
                  >À propos de Miroir d'art</router-link
                >
              </li>
              <!-- <li class="list-element"><a href="javascript:void(0);" class="">Guide du collectionneur</a></li> -->
              <li class="list-element">
                <router-link to="/comment-ca-marche" class="animated-underline"
                  >Comment ça marche</router-link
                >
              </li>
              <li class="list-element">
                <router-link to="" class="animated-underline"
                  >Contactez-nous</router-link
                >
              </li>
              <li class="list-element">
                <router-link to="/conditions-generales-d-utilisation" class="animated-underline">
                  Conditions générales d'utilisation
                </router-link>
              </li>
              <li class="list-element">
                <router-link to="/conditions-generales-de-ventes" class="animated-underline">
                  Conditions générales de ventes
                </router-link>
              </li>
              <li class="list-element">
                <router-link to="/mentions-legales" class="animated-underline">
                  Mentions légales
                </router-link>
              </li>
            </ul>
          </div>
          <div class="col-6 col-lg-3 mb-6">
            <ul class="list-unstyled">
              <li class="list-element">
                <router-link to="oeuvres" class="text-capitalize">
                  <a class="animated-underline">Oeuvres</a>
                </router-link>
              </li>
              <li class="list-element footer_link" v-for="category in allCategories" :key="category.category_id">
                <router-link :to="`/oeuvres?category_id=${category.category_id}`" class="animated-underline text-capitalize">{{ category.name }}</router-link>
              </li>
            </ul>
          </div>
          <div class="col-6 col-lg-3 mb-6">
            <ul class="list-unstyled">
              <li class="list-element">
                <a href="javascript:void(0);" class="animated-underline">Artistes</a>
              </li>
              <li class="list-element">
                <a href="javascript:void(0);" class="animated-underline">Galeries</a>
              </li>
              <li class="list-element">
                <a href="javascript:void(0);" class="animated-underline">Collections</a>
              </li>
              <li class="list-element">
                <a href="javascript:void(0);" class="animated-underline">Expositions</a>
              </li>
              <li class="list-element">
                <a href="javascript:void(0);" class="animated-underline">Magazine</a>
              </li>
              <li class="list-element d-block d-lg-none">
                <div class="footer_box">
                  <h6 class="">Galeries</h6>
                  <p class="mb-4 footer_link body-1">
                    Vous souhaitez exposer sur Ekekos.art?
                  </p>
                  <a href="/" class="bold body-2">Remplissez votre dossier</a>
                </div>
              </li>
            </ul>
          </div>
          <div class="col-6 col-lg-3">
            <ul class="list-unstyled">
              <li class="list-element">
                <a to="/mon-compte" class="animated-underline">Mon compte</a>
              </li>
              <li class="list-element footer_link">
                <a href="javascript:void(0);" class="animated-underline">Mes informations</a>
              </li>
              <li class="list-element footer_link">
                <a href="javascript:void(0);" class="animated-underline">Mes adresses</a>
              </li>
              <li class="list-element footer_link">
                <a href="javascript:void(0);" class="animated-underline">Mes commandes</a>
              </li>
            </ul>
          </div>
        </div>
        <!--  -->
        <hr class="bg-white" />
        <!--  -->
        <div class="_flex _wrap _between w-100 pt-3">
          <div class="order-2 order-md-1 mt-5 mt-0-lg">
            <div class="_flex align-items-start">
              <div class="display-3 normal mr-6">
                <img src="../../assets/logo-2.png" alt="" class="text-white" style="width:50px!important;"/>
              </div>
              <div>
                <p class="m-0 mb-3">Suivre <span class="medium">Miroir d'art</span></p>
                <div class="_flex title">
                  <a href="javascript:void(0);"><i class="fab fa-facebook mr-4"></i></a>
                  <a href="javascript:void(0);"><i class="fab fa-twitter mr-4"></i></a>
                  <a href="javascript:void(0);"><i class="fab fa-pinterest mr-4"></i></a>
                  <a href="javascript:void(0);"><i class="fab fa-instagram mr-4"></i></a>
                </div>
              </div>
            </div>
          </div>
          <div class="order-1 order-md-2">
            <div class="body-2 mb-2">S'abonner à la Newsletter</div>
            <div class="_flex border pr-3">
              <input type="email" v-model="newsletter" placeholder="Votre adresse email" class="form-control bg-transparent border-0 body-1 py-0 rounded-0"/>
              <a class="c-pointer"><i class="fal fa-angle-right" style="font-size:30px;"></i></a>
            </div>
          </div>
        </div>
        <p class="text-center caption px-10-md px-20-lg mb-0 mt-4 footer_link">
          <span class="medium">Miroir d'art</span> est une place de marché
          dédiée à la vente d'œuvres d'art africain traditionel et contemporain
          en ligne. Galeries d'art, exposez vos œuvres sur Internet avec
          <span class="medium">Miroir d'art</span>. Amateurs d'art, concrétisez
          votre achat de tableau contemporain, de photo d'art ou encore de
          sculpture contemporaine à travers des milliers d'œuvres d'art en
          ligne, issues d'artistes contemporains rigoureusement sélectionnés !
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  data: () => ({
    newsletter: "",
    allCategories: null
  }),
  computed: {
    ...mapGetters([]),
    categories() {
      return this.$store.getters.mainCategories;
    },
  },
  methods: {
    ...mapMutations([]),
    ...mapActions([
      "axiosGetAllData",
    ]),
    getCategories() {
      this.loading = true;
      this.axiosGetAllData({table: "retrieveCategories"})
          .then( response => {
            if(response.status === 200){
              this.allCategories = response.data.data
            }
            this.loading = false;
          })
          .catch()
    },
  },
  created() {
    this.getCategories()
  }

};
</script>

<style></style>
