<template>
  <div class="d-flex flex-column align-items-center justify-content-center py-20">
    <h5>Vous n'avez pas encore créer votre profil d'artiste</h5>
    <br />
    <button class="btn black" @click="$router.push('/dashboard/profil')">
      <i class="icon-profile mr-2 body-1"></i> Ajouter profil
    </button>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
