<template>
  <div class="alert alert-success alert-dismissible fade show" role="alert">
    <div class="alert-row">
      <div class="alert-icon">
        <i class="fas fa-check-circle icon"></i>
      </div>
      <div class="alert-content" v-html="message"></div>
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
        @click="$store.commit('SET_ERROR', null)"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      required: true,
    },
  },
};
</script>
