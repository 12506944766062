<template>
  <!-- Sign Modal -->
  <div
    class="modal fade"
    id="notConnectedModal"
    data-backdrop="static"
    data-keyboard="false"
    tabindex="-1"
    aria-labelledby="notConnectedModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content rounded-0 px-5-md px-8-lg">
        <div class="modal-header pt-8 border-bottom-0">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i class="icon-exit" style="font-size:14px;"></i>
          </button>
        </div>
        <div class="modal-body">
          <h5 class="text-center">
            {{
              message ||
                "Vous n'êtes pas connecté pour effectuer cette opération!"
            }}
          </h5>
          <br />
          <div class="d-flex">
            <button
              class="btn btn-follow btn-block black mb-4 mt-2 py-0"
              @click="openSignModal"
            >
              J'ouvre un compte
            </button>
            <button
              class="btn btn-follow btn-block black mb-4 mt-2 py-0"
              @click="openSignModal"
            >
              Me connecter
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: false,
      default: null,
    },
  },
  methods: {
    openSignModal() {
      $("#notConnectedModal").modal("hide");
      $("#signModal").modal("show");
    },
  },
};
</script>
