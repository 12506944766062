<template>
  <div>
    <!-- Top navbar -->
    <nav class="d-none d-lg-flex navbar border-bottom _flex _wrap _between py-0">
      <!-- Left side -->
      <ul class="navbar-nav _flex">
        <li class="nav-item top_separator dropdown-mini">
          <a href="" class="nav-link dropbtn-mini">FR</a>
          <div class="dropdown-content-mini dropdown-content-mini-left">
            <a href="" class="">Français</a>
            <a href="" class="">English</a>
          </div>
        </li>
        <li class="nav-item top_separator dropdown-mini">
          <a href="" class="nav-link dropbtn-mini">$ (USD)</a>
          <div class="dropdown-content-mini dropdown-content-mini-left">
            <a href="" class="">$ (USD)</a>
            <a href="" class="">€ (EUR)</a>
            <a href="" class="">£ (GBP)</a>
          </div>
        </li>
        <li class="nav-item">
          <router-link to="/aide" class="nav-link">Besoin d'aide?</router-link>
        </li>
      </ul>
      <!-- Right side -->
      <ul class="navbar-nav _flex">
        <li class="nav-item top_separator" v-if="!getToken">
          <a data-toggle="modal" data-target="#signModal" class="nav-link mr-4 _flex c-pointer">
            <i class="icon-profile mr-2 icon-large"></i> S'inscrire / Se connecter</a>
        </li>
        <router-link to="/dashboard" class="nav-link mr-4 _flex" v-if="(getUserData && getUserData.userData) ? getUserData.userData.type_user === 'artist' : false">
          <i class="fas fa-store mr-2 is-18"></i> Ma Galerie
        </router-link>
        <li class="nav-item dropdown-mini top_separator" v-if="getToken">
          <a class="nav-link mr-4 _flex dropbtn-mini"><i class="icon-profile mr-2 icon-large"></i>Mon compte</a>
          <div class="dropdown-content-mini dropdown-content-favorites tiny text-left">
            <router-link to="/mon-compte" class="">Mon Profil</router-link>
            <a data-toggle="modal" data-target="#logoutModal" class="c-pointer">Se déconnecter</a>
          </div>
        </li>
        <li class="nav-item dropdown-mini">
          <a class="nav-link dropbtn-mini mr-4"><i class="icon-wishlist icon-large"></i></a>
          <div class="dropdown-content-mini dropdown-content-favorites tiny">
            <router-link to="/favoris/oeuvres" class="">Mes oeuvres favories</router-link>
            <router-link to="/favoris/artistes" class="">Mes artistes favoris</router-link>
            <div class="dropdown-divider"></div>
            <a href="#" class="">Recommandés pour vous</a>
          </div>
        </li>
        <li class="nav-item">
          <router-link to="/panier" class="nav-link">
            <div class="position-relative">
              <i class="icon-cart icon-large"></i>
              <span class="cart_count" v-if="cart_count > 0">{{cart_count}}</span>
            </div>
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  data () {
    return {}
  },
  methods: {
    ...mapMutations([]),
    ...mapActions([
      'tryAutoLogin'
    ]),
  },
  watch: {},
  computed: {
    ...mapGetters([
      "cart_count",
      "getToken",
      "getUserData",
    ]),
  },
  beforeCreate() {},
  created() {
    this.tryAutoLogin()
  },
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {}
};
</script>
