import firebase from "firebase/app";
require("firebase/analytics");
require("firebase/auth");
require("firebase/functions");
require("firebase/storage");

// Firebase Settings
firebase.initializeApp({
  apiKey: "AIzaSyDrLwDgXHiagdmjl7I4Vvjem3Nn5ggCoMk",
  authDomain: "miroir-dart-test.firebaseapp.com",
  projectId: "miroir-dart-test",
  storageBucket: "miroir-dart-test.appspot.com",
  messagingSenderId: "553537046433",
  appId: "1:553537046433:web:0b56f6e819ab0f9bb52de0",
});


export const storage = firebase.storage();

export const auth = firebase.auth();

export const user_id = auth.currentUser?.uid;

export const GOOGLE_PROVIDER = new firebase.auth.GoogleAuthProvider();

export const FACEBOOK_PROVIDER = new firebase.auth.FacebookAuthProvider();