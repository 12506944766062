<template>
  <div>
    <!-- Header -->
    <main-header />
    <!--  -->
    <section class="preheader">
      <div class="pt-10 pb-15">
        <div class="row w-100">
          <div class="col-12 col-md-8 offset-md-2 d-flex flex-column align-items-center justify-content-center text-center">
            <h2 class="medium">DÉCOUVREZ ET ACHETEZ L’ART QUE VOUS AIMEZ</h2>
            <h4 class="normal"><i>Nos artistes dénichent pour vous les meilleurs artistes contemporains</i></h4>
            <router-link to="/artistes">
              <button class="btn button-outline-black rounded-0 px-3 mt-5">Découvrez nos meilleurs œuvres</button>
            </router-link>
          </div>
        </div>
      </div>
    </section>

    <!-- show categories filter -->
    <section>
      <div class="container py-10 py-15-md">
        <div class="mb-8">
          <p class="headline bold m-0">Parcourir par catégories</p>
          <p class="mt-2">
            Explorez votre catalogue d'œuvres par style et technique
          </p>
        </div>
        <!--  -->
        <div class="row">
          <div class="col-12 col-sm-6 col-lg-4" v-for="(category, i) in allCategories" :key="i">
            <div @click="filterCategory(category.category_id)" class="artwork_catalogue" :style="`background-image:url(${category.img_src})`">
              <div class="artwork_catalogue_content">
                <h5 class="text-capitalize">{{ category.name }}</h5>
                <a href="" class="animated-underline">Découvrir</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- show prices filter -->
    <section>
      <div class="container">
        <div class="mb-8">
          <p class="headline bold m-0">Parcourir par prix</p>
          <!-- <p class="mt-2">Explorez votre catalogue d'œuvres par style et technique</p> -->
        </div>
        <!--  -->
        <div class="row">
          <div class="col-6 col-md-4 col-lg-2 mb-5" @click="filterByPrice('<500')">
            <div class="price_box">
              <div class="price_title">Moins de $500</div>
            </div>
          </div>
          <div class="col-6 col-md-4 col-lg-2 mb-5" @click="filterByPrice('500-5000')">
            <div class="price_box">
              <div class="price_title">$500 - $5,000</div>
            </div>
          </div>
          <div class="col-6 col-md-4 col-lg-2 mb-5" @click="filterByPrice('5000-15000')">
            <div class="price_box">
              <div class="price_title">$5,000 - $15,000</div>
            </div>
          </div>
          <div class="col-6 col-md-4 col-lg-2 mb-5" @click="filterByPrice('15000-50000')">
            <div class="price_box">
              <div class="price_title">$15,000 - $50,000</div>
            </div>
          </div>
          <div class="col-6 col-md-4 col-lg-2 mb-5" @click="filterByPrice('50000-100000')">
            <div class="price_box">
              <div class="price_title">$50,000 - $100,000</div>
            </div>
          </div>
          <div class="col-6 col-md-4 col-lg-2 mb-5" @click="filterByPrice('>100000')">
            <div class="price_box">
              <div class="price_title">Plus de $100,000</div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- show all artworks -->
    <section>
      <div class="container py-10 py-15-md">
        <div class="_flex _between flex-wrap mb-8">
          <div>
            <p class="headline bold m-0">Meilleures œuvres</p>
            <p class="mt-2">
              Découvrez les œuvres que nos collectionneurs adorent
            </p>
          </div>
          <div class="medium" style="font-size:12.5px">
            <a href="" class="animated-underline">Voir toutes les œuvres</a>
          </div>
        </div>
        <div class="d-flex flex-row">
          <div class="artists_follow_artworks">
            <div class="best-artworks-section d-flex">
              <div v-for="artwork in artworks" :key="artwork.artwork_id">
                <artwork-home :artwork="artwork" />
              </div>
            </div>
            <div v-if="!artworks.length" class="text-center">
              Chargement ...
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- show all artists -->
    <section class="artists_follow">
      <div class="container">
        <div class="_flex _between flex-wrap mb-4">
          <div>
            <h3 class="bold m-0">Nos artistes</h3>
            <p class="mt-2">
              Découvrez nos artistes
            </p>
          </div>
          <div class="medium" style="font-size:12.5px">
            <a href="" class="animated-underline">Voir tous les artistes</a>
          </div>
        </div>

        <div class="best-artists-section d-flex">
          <div v-for="artist in artists" :key="artist.user_id">
            <artist-home :artist="artist" @followArtist="onFollowArtist" @unfollowArtist="onUnfollowArtist"/>
          </div>
        </div>
        <div v-if="!(artists.length > 0)" class="text-center">Chargement ...</div>
      </div>
    </section>
    <!-- Footer -->
    <main-footer />
  </div>
</template>

<script>
import { supabase } from "../lib/supabase";
import ArtistHome from "../components/cards/ArtistHome.vue";
import ArtworkHome from "../components/cards/ArtworkHome.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";

const ARTWORKS_DB = "artworks";
const ARTISTS_DB = "artists";

export default {
  components: { ArtworkHome, ArtistHome },
  data: () => ({
    artworks: [],
    artists: [],
    allCategories: [],
  }),
  computed: {
    ...mapGetters([
      "isLoggedIn",
      "favorite_artists",
      "favorite_artworks",
      "getEmptyUserPicture",
      "getEmptyArtworkPicture",
    ]),
  },
  methods: {
    ...mapMutations([]),
    ...mapActions([
        "axiosGetAllData",
        "axiosGetOneData",
    ]),
    filterByPrice(prix) {
      return this.$router.push({
        path: "/filter-par-prix",
        query: {
          prix,
        },
      });
    },
    filterCategory(category_id) {
      this.$router.push({
        path: "/oeuvres",
        query: {
          category_id: category_id,
        },
      });
    },
    onFollowArtist(artist) {
      const { commit, dispatch } = this.$store;
      if (!this.isLoggedIn) {
        return $("#notConnectedModal").modal("show");
      }

      return dispatch("addArtistToFavorites", artist).then((res) => {
        if (res.success) {
          const lFavoriteArtistes = [];
          lFavoriteArtistes.push(res.data);
          lFavoriteArtistes.concat(this.favorite_artists);
          commit("SET_FAVORITE_ARTISTS", lFavoriteArtistes);
        }
      });
    },
    onUnfollowArtist(artist) {
      const { commit, dispatch } = this.$store;
      if (!this.isLoggedIn) {
        return $("#notConnectedModal").modal("show");
      }

      return dispatch("removeArtistToFavorites", artist.id).then((res) => {
        if (res.success) {
          const lFavoriteArtistes =
            this.favorite_artists.filter((a) => a.artist_id !== artist.id) ||
            [];
          commit("SET_FAVORITE_ARTISTS", lFavoriteArtistes);
        }
      });
    },
    getCategories(){
      this.loading = true
      this.axiosGetAllData({table: "retrieveCategories"})
          .then( response => {
            if(response.status === 200){
              this.allCategories = response.data.data
            }
            this.loading = false;
          })
          .catch()
    },
    getArtists(){
      this.axiosGetAllData({table: "retrieveArtists"})
          .then( response => {
            if(response.status === 200){
              this.artists = response.data.data
              /*this.$toast.success(response.data.message, {
                icon: "fas fa-2x fa-check-circle",
              })*/
            }else {
              this.artworks = []
              /*this.$toast.warning(response.data.message, {
                icon: "fas fa-2x fa-exclamation",
              })*/
            }
            this.loading = false;
          })
          .catch()
    },
    getArtworks(){
      this.axiosGetAllData({table: "retrieveArtworks"})
          .then( response => {
            if(response.status === 200){
              this.artworks = response.data.data
              /*this.$toast.success(response.data.message, {
                icon: "fas fa-2x fa-check-circle",
              })*/
            }else {
              this.artworks = []
              /*this.$toast.warning(response.data.message, {
                icon: "fas fa-2x fa-exclamation",
              })*/
            }
            this.loading = false;
          })
          .catch()
    },
  },
  mounted() {},
  created() {
    this.getCategories()
    this.getArtworks()
    this.getArtists()
  }
};
</script>

<style></style>
