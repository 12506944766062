<template>
  <div class="card rounded-0 artists_follow_card">
    <div class="">
      <router-link class="artists_follow_img_link" :to="`/artiste/${artist.user_id}`" :title="`Voir page de ${fullname}`">
        <div :style="`background-image:url(${ artist.data_picture ? artist.data_picture.src : getEmptyUserPicture })`" class="artists_follow_img"></div>
      </router-link>
      <div class="mt-1 mb-3">
        <router-link :to="`/artiste/${ artist.user_id} `" class="bold text-uppercase m-0">
          {{ fullname }}
        </router-link>
        <div class="artist-cell-item__categories" v-if="artist.categories_ids && artist.categories_ids.length > 0">
          <span v-for="category in artist.categories_ids" :key="category.category_id">
            {{ category.name }}
          </span>
        </div>
        <div class="artist-cell-item__followers" v-if="parseInt(artist.num_artworks)">
          {{ parseInt(artist.num_artworks)  || 0 }} oeuvre{{ parseInt(artist.num_artworks) > 1 ? "s" : "" }}
        </div>
      </div>
      <button v-if="!following" class="btn btn-follow black btn-block text-uppercase py-0 _flex _center" @click="onFollow" :disabled="author">
        <i class="icon-follow mr-1"></i> Suivre
      </button>
      <button v-if="following" class="btn btn-follow black btn-block text-uppercase py-0 _flex _center" style="background:red!important" @click="onUnfollow" :disabled="author">
        <i class="icon-follow mr-1"></i> Ne pas suivre
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  props: {
    artist: {
      required: true,
      type: Object,
    },
  },
  emits: [
    "followArtist",
    "unfollowArtist"
  ],
  computed: {
    ...mapGetters([
      "user",
      "favorite_artists",
      "getEmptyUserPicture",
      "getEmptyArtworkPicture",
    ]),
    fullname() {
      return `${this.artist.last_name || ""} ${this.artist.middle_name || ""} ${this.artist.first_name || ""}`;
    },
    author() {
      if (this.user && this.user.id === this.artist.id) {
        return true;
      }
      return false;
    },
    following() {
      if (!this.user) return false;
      if (!this.favorite_artists.length) return false;

      console.log("artist.id: ", this.artist.id);

      console.log("artists ID", this.favorite_artists.map(a => a.artist_id));
      const artist = this.favorite_artists.find(
        (artist) => artist.artist_id.toString() == this.artist.id.toString()
      );
      console.log("following data: ", artist);
      if (artist) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    ...mapMutations([]),
    ...mapActions([]),
    onFollow() {
      this.$emit("followArtist", this.artist);
    },
    onUnfollow() {
      this.$emit("unfollowArtist", this.artist);
    },
  },
  created() {
    console.log(this.artist)
  }
};
</script>

<style></style>
