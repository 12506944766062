<template>
  <div class="card rounded-0 artists_follow_card artwork-card">
    <div class="">
      <router-link :to="`/oeuvre/${artwork.artwork_id}`" class="artists_follow_img_link">
        <div :style="`background-image:url(${artwork.data_pictures ? getMainPictureArtwork(artwork.data_pictures) : getEmptyArtworkPicture})`" class="artists_follow_img">
          <img src="/img/logo.png" alt="" v-if="!artwork.images.trim()">
        </div>
      </router-link>
      <div class="mt-1">
        <div class="bold text-uppercase m-0">
          {{ artwork.name || '' }}
        </div>
        <div class="artist-cell-item__bio text-capitalize">{{ artist_name || ''}}</div>
        <div class="_flex _between mt-2">
          <div class="medium text-capitalize">{{artwork.data_category.name || ''}}</div>
          <div class="bold">{{artwork.price || '' | number}} {{artwork.currency_id || ''}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from "vuex";

export default {
  props: {
    artwork: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
        "getEmptyArtworkPicture",
        "getEmptyUserPicture",
    ]),
    artist_name() {
      return `${this.artwork.data_user.last_name || ""} ${this.artwork.data_user.middle_name || ""} ${this.artwork.data_user.first_name || ""}`;
    },
  },
  methods: {
    ...mapMutations([]),
    ...mapActions([]),
    getMainPictureArtwork(arr_pictures){
      let mainPicture = arr_pictures.filter(picture => parseInt(picture.artwork_picture_id) === 0)[0]
      return mainPicture.src
    },
  },
  created() {},

};
</script>

<style></style>
