import { supabase } from "../../lib/supabase";
import { handleUserNotAuth, handleError } from "../errors";

const ORDERS_DB = "orders";

const state = {
  orders: [],
  orders_count: 0,
};

const getters = {
  orders: (state) => state.orders,
  orders_count: (state) => state.orders_count,
};

const mutations = {
  SET_ORDERS(state, payload) {
    return (state.orders = payload);
  },
  SET_ORDERS_COUNT(state, payload) {
    return (state.orders_count = payload);
  },
};

const actions = {
  async fetchOrders({ commit, getters }) {
    const isLoggedIn = getters.isLoggedIn;

    if (!isLoggedIn) return;

    const user_id = getters.user_id;

    const { data, error, count } = await supabase
      .from(ORDERS_DB)
      .select(
        `
        *, 
        artworks ( * )
      `,
        { count: "exact" }
      )
      .match({ user_id });

    if (error) {
      handleError();
    }

    console.log(data);

    commit("SET_ORDERS", data);
    commit("SET_ORDERS_COUNT", count);
  },
  async addOrder({ commit, getters }, payload) {
    const isLoggedIn = getters.isLoggedIn;

    if (!isLoggedIn) handleUserNotAuth(commit);

    const user_id = getters.user_id;

    const { data, error } = await supabase
      .from("orders")
      .insert([{ user_id, ...payload, status: "pending" }]);

    if (error) {
      handleError();
    }

    console.log(data);

    return { success: true, data };
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
