<template>
  <div class="d-flex flex-column align-items-center justify-content-center py-20">
    <h5>Vous n'avez pas encore effectuer des commandes</h5>
    <br />
    <button class="btn black" @click="$router.push('/oeuvres')">
      <i class="fas fa-cart-plus mr-2 body-1"></i> Commander
    </button>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>

</style>