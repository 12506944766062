import Vue from "vue";
import Vuex from "vuex";
import axios from 'axios'
import modules from "./modules";
import { COUNTRIES } from '../data/countries'

Vue.use(Vuex);

export default new Vuex.Store({
    modules,
    state: {
        baseURL: 'https://miror.al-legacy.com/api/',
        emptyUserPicture: "https://miror.al-legacy.com/api/upload_pictures/empty/user.jpg",
        emptyArtworkPicture: "https://miror.al-legacy.com/api/upload_pictures/empty/artwork.jpg",
        // baseURL: 'http://127.0.0.1/miror_art_api/api/',
        // emptyUserPicture: "http://127.0.0.1/miror_art_api/api/upload_pictures/empty/user.jpg",
        // emptyArtworkPicture: "http://127.0.0.1/miror_art_api/api/upload_pictures/empty/artwork.jpg",
        token: null,
        userData: null,
        countries: COUNTRIES,
    },
    getters: {
        getBaseURL: state => {
            return state.baseURL;
        },
        getToken: state => {
            return state.token
        },
        getUserData: state => {
            return state.userData
        },
        getCountries: state => {
            return state.countries
        },
        getEmptyUserPicture: state => {
            return state.emptyUserPicture
        },
        getEmptyArtworkPicture: state => {
            return state.emptyArtworkPicture
        },
    },
    mutations: {
        utilisateurAuth(state, payload) {
            state.token = payload.token
        },
        suppressionUtilisateurAuth(state) {
            state.userData = null
            state.token = null
        },
        setUserData(state, payload) {
            state.userData = payload
        },
    },
    actions: {
        setLogoutTimer({ dispatch }) {
            setTimeout(() => {
                dispatch('axiosUtilisateurDeconnection')
            }, 3600000)
        },
        tryAutoLogin({ commit, state, dispatch }) {
            const token = sessionStorage.getItem('uToken')
            if (!token) return
            commit('utilisateurAuth', {
                token: token,
            })
            dispatch('axiosGetOneData', { table: 'profile', table_id: state.token })
                .then(response => {
                    commit('setUserData', {
                        userData: response.data.data,
                    })
                })
                .catch(error => {})
        },
        axiosUtilisateurDeconnection({ commit }) {
            commit('suppressionUtilisateurAuth')
            sessionStorage.removeItem('uToken')
        },
        axiosUtilisateurAuth({ commit, state, dispatch }, data) {
            return axios.post(state.baseURL + data.table + '/', JSON.stringify(data), {})
                .then(response => {
                    if (response.status === 200) {
                        commit('utilisateurAuth', {
                            token: response.data.data.user_id,
                        })
                        commit('setUserData', {
                            userData: response.data.data,
                        })
                        dispatch('setLogoutTimer')
                        sessionStorage.setItem('uToken', response.data.data.user_id)
                    }
                    return response
                })
                .catch(error => {
                    return error.response
                });
        },
        axiosRemoveFileData({ commit, state, dispatch }, data) {
            return axios.delete(state.baseFileURL + 'remove_file.php', data)
                .then(response => {
                    return [true, response.data.message, '']
                })
                .catch(error => {
                    dispatch('changeMessageApp', {
                        status: error.response.data.status,
                        message: error.response.data.messages,
                    })
                });
        },
        axiosPostFileData({ commit, state, dispatch }, data) {
            return axios.post(state.baseURL + data.dataForm.table_file + '/', data.data, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error.response
                });
        },
        axiosPostData({ commit, state, dispatch }, data) {
            return axios.post(state.baseURL + data.table + '/', JSON.stringify(data), {})
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error.response
                });
        },
        axiosGetAllData({ commit, state, dispatch }, data) {
            return axios.get(state.baseURL + data.table + '/', {})
                .then(response => {
                    return response;
                })
                .catch(error => {
                    return error.response
                });
        },
        axiosGetOneData({ commit, state, dispatch }, data) {
            return axios.get(state.baseURL + data.table + '/' + data.table_id)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    return error.response
                });
        },
        axiosPutData({ commit, state, dispatch }, data) {
            return axios.put(state.baseURL + data.table + '/' + data.table_id, JSON.stringify(data), {})
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error.response
                });
        },
        axiosDeleteData({ commit, state, dispatch }, data) {
            return axios.delete(state.baseURL + data.table + '/' + data.table_id)
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error.response
                });
        },
        axiosGetNumRows({ commit, state, dispatch }, data) {
            return axios.get(state.baseURL + 'total_' + data.table)
                .then(response => response.data)
                .catch(error => {
                    dispatch('changeMessageApp', {
                        status: error.response.data.status,
                        message: error.response.data.messages,
                    })
                    return error
                })
        },
        axiosGetNumRowsById({ commit, state, dispatch }, data) {
            return axios.get(state.baseURL + 'total_' + data.table + '/' + data.id)
                .then(response => response.data)
                .catch(error => {
                    dispatch('changeMessageApp', {
                        status: error.response.data.status,
                        message: error.response.data.messages,
                    })
                    return error
                })
        },
        axiosGetPaginatedData({ commit, state, dispatch }, data) {
            return axios.get(state.baseURL + 'pagination_' + data.table + '/' + data.pageIdx + '/' + data.pageNum)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    dispatch('changeMessageApp', {
                        status: error.response.data.status,
                        message: error.response.data.messages,
                    })
                    return error
                });
        },
        axiosGetPaginatedDataById({ commit, state, dispatch }, data) {
            return axios.get(state.baseURL + 'pagination_' + data.table + '/' + data.id + '/' + data.pageIdx + '/' + data.pageNum)
                .then(response => {
                    return response.data;
                })
                .catch(error => {
                    dispatch('changeMessageApp', {
                        status: error.response.data.status,
                        message: error.response.data.messages,
                    })
                    return error
                });
        }
    },
    strict: process.env.NODE_ENV !== "production",
});