<template>
  <div>
    <!-- Navigation -->
    <section>
      <sub-header />
      <!-- Middle navbar -->
      <nav class="d-none d-lg-flex navbar navbar-expand-lg navbar-light subheading medium" id="navbar">
        <a class="navbar-brand bold" href="/">
          <img src="/img/logo.png" alt="" class="" style="width:58px!important;"/>
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <template v-if="!searching">
            <!-- Left side -->
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <router-link class="nav-link c-pointer " to="/oeuvres"><b>Oeuvres</b></router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link c-pointer " to="/artistes"><b>Artistes</b></router-link>
              </li>
              <template v-if="allCategories">
                <li class="nav-item text-capitalize" v-for="category in allCategories">
                  <a class="nav-link c-pointer " @click="filterCategory(category.category_id)">{{ category.name }}</a>
                </li>
              </template>
            </ul>
            <!-- Right side -->
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <div id="search-box-widget">
                  <input @keyup.enter="onSearch" v-model.trim="search" type="search" class="autocomplete-input" maxlength="512" autocapitalize="off" autocomplete="off" autocorrect="off" placeholder="Oeuvre par mot clé..."/>
                </div>
              </li>
            </ul>
          </template>
          <template v-if="searching">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item w-100">
                <input type="text" class="medium text-muted text-center border-bottom" placeholder="Rechercher oeuvres d'art par mot clé..." style="min-width:400px;font-size:18px;border-left:0;border-right:0;border-top:0" :autofocus="true" v-model.trim="search" @keyup.enter="onSearch"/>
              </li>
            </ul>
            <!-- Right side -->
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <a class="nav-link body-1 _flex c-pointer" @click="searching = false">
                  <i class="icon-exit mr-1 bold" style="font-size:12px;"></i> Fermer
                </a>
              </li>
            </ul>
          </template>
        </div>
      </nav>
      <!-- Mobile Menu -->
      <nav class="d-block d-lg-none navbar navbar-light bg-white subheading medium">
        <div class="_flex _between">
          <div class="_flex">
            <router-link to="/sidenav" class="bar-container mr-5">
              <div class="bar1"></div>
              <div class="bar2"></div>
              <div class="bar3"></div>
            </router-link>
          </div>
          <div class="_flex">
            <a class="navbar-brand bold" href="#">
              <img src="/img/logo.png" alt="" class="" style="width:38px!important;"/>
            </a>
          </div>
          <div class="_flex">
            <router-link to="/panier" class="nav-link">
              <i class="icon-cart title normal"></i>
            </router-link>
          </div>
        </div>
      </nav>
      <!-- Bottom navbar -->
      <nav class="text-center p-3 bg-light">
        <p class="m-0">Inscrivez-vous à notre newsletter et bénéficiez de <b>$15 OFFERTS</b> sur votre 1ère commande</p>
      </nav>
    </section>
    <!-- Scroll Button -->
    <button @click="topFunction" id="myBtn" title="Go to top">
      <svg focusable="false" preserveAspectRatio="xMidYMid meet" style="will-change:transform" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 32 32" aria-hidden="true" fill="currentColor">
        <path d="M16 13L6 23l1.41 1.41L16 15.83l8.59 8.58L26 23 16 13zM4 7h24v2H4z"/>
      </svg>
    </button>
  </div>
</template>

<script>
import SubHeader from "./SubHeader.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  data: () => ({
    searching: false,
    search: "",
    allCategories: null,
  }),
  components: { SubHeader },
  methods: {
    ...mapMutations([]),
    ...mapActions([
        "axiosGetOneData",
        "axiosGetAllData",
    ]),
    onSearch() {
      this.$router.push({
        path: "/resultats",
        query: {
          q: this.search,
        },
      });
    },
    changeMenu(x) {
      x.classList.toggle("change");
    },
    topFunction() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
    filterCategory(category_id) {
      this.$router.push({
        path: "/oeuvres",
        query: {
          category_id: category_id,
        },
      });
    },
  },
  computed: {
    ...mapGetters([])
  },
  mounted() {
    // Get the navbar
    var navbar = document.getElementById("navbar");

    //Get the button:
    var mybutton = document.getElementById("myBtn");

    // Get the offset position of the navbar
    var sticky = navbar.offsetTop;

    function scrollFunction() {
      // Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position

      // When the user scrolls down 20px from the top of the document, show the button

      if (
        document.body.scrollTop > 400 ||
        document.documentElement.scrollTop > 400
      ) {
        mybutton.style.display = "block";
      } else {
        mybutton.style.display = "none";
      }

      if (window.pageYOffset >= sticky) {
        navbar.classList.add("sticky");
      } else {
        navbar.classList.remove("sticky");
      }
    }

    window.onscroll = function() {
      scrollFunction();
    };
  },
  created(){
    this.axiosGetAllData({table: "retrieveCategories"})
        .then( response => {
          if(response.status === 200){
            this.allCategories = response.data.data
          }
          this.loading = false;
        })
        .catch()
  }
};
</script>

<style></style>
