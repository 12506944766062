<template>
  <div id="snackbar">{{ alert }}</div>
</template>

<script>
export default {
  computed: {
    alert() {
      return this.$store.getters.alert;
    },
  },
  watch: {
    alert(value) {
      if (value != null) {
        this.showSnackbar();
      }
    },
  },
  methods: {
    showSnackbar() {
      var x = document.getElementById("snackbar");
      x.className = "show";
      setTimeout(() => {
        this.$store.commit("SET_ALERT", null);
        x.className = x.className.replace("show", "");
      }, 6000);
    },
  },
};
</script>

<style scoped>
#snackbar {
  visibility: hidden;
  width: 100vw;
  background-color: var(--success);
  color: #fff;
  text-align: center;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 0;
  bottom: 0;
  font-size: 16px;
  z-index:100000!important;
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {bottom: -30px; opacity: 0;} 
  to {bottom: 0; opacity: 1;}
}

@keyframes fadein {
  from {bottom: -30px; opacity: 0;} 
  to {bottom: 0; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 0; opacity: 1;} 
  to {bottom: -30px; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 0; opacity: 1;} 
  to {bottom: -30px; opacity: 0;}
}

/* #snackbar {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
  font-size: 17px;
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
} */
</style>
