import { required, minLength, email } from 'vuelidate/lib/validators'

export const FORM_SIGN_IN_SIGN_UP = {
	login: {
		email: {
			required,
			email,
		},
		password: {
			required,
			minLength: minLength(8)
		},
	},
	signup: {
		email: {
			required,
			email,
		},
		password: {
			required,
			minLength: minLength(8),
		}
	}
}