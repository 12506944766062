import { supabase } from "../../lib/supabase";

const state = {
  placeholder_avatar: "",
  loading: false,
  error: null,
  alert: null,
  countries: [],
  categories: [],
  mainCategories: [
    {
      name: "Peinture",
      value: "peinture",
      image: require("../../assets/images/category-3.jpg"),
      description: "",
    },
    {
      name: "Sculpture",
      value: "sculpture",
      image: require("../../assets/images/category-2.jpg"),
      description: "",
    },
    {
      name: "Edition",
      value: "edition",
      image: require("../../assets/images/category-4.jpg"),
      description: "",
    },
    {
      name: "Photographie",
      value: "photographie",
      image: require("../../assets/images/category-5.jpg"),
      description: "",
    },
    {
      name: "Dessin",
      value: "dessin",
      image: require("../../assets/images/category-6.jpg"),
      description: "",
    },
    {
      name: "Tableau",
      value: "tableau",
      image: require("../../assets/images/category-1.jpg"),
      description: "",
    },
  ],
};

const getters = {
  placeholder_avatar: (state) => state.placeholder_avatar,
  loading: (state) => state.loading,
  error: (state) => state.error,
  alert: (state) => state.alert,
  countries: (state) => state.countries,
  categories: (state) => state.categories,
  mainCategories: (state) => state.mainCategories,
};

const mutations = {
  SET_ERROR(state, payload) {
    return (state.error = payload);
  },
  SET_ALERT(state, payload) {
    return (state.alert = payload);
  },
  SET_LOADING(state, payload) {
    return (state.loading = payload);
  },
  CLEAR_ERROR(state) {
    return (state.error = null);
  },
  SET_COUNTRIES(state, payload) {
    return (state.countries = payload);
  },
  SET_CATEGORIES(state, payload) {
    return (state.categories = payload);
  },
};

const actions = {
  async fetchCountries({ commit }) {
    const { data, error } = await supabase
      .from("countries")
      .select()
      .order("id", { ascending: false });

    if (error) {
      console.error(error);
      return;
    }

    commit("SET_COUNTRIES", data);
  },
  async fetchCategories({ commit }) {
    const { data, error } = await supabase
      .from("categories")
      .select()
      .order("id", { ascending: false });

    if (error) {
      console.error(error);
      return;
    }

    commit("SET_CATEGORIES", data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
