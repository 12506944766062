<template>
  <div class="d-flex flex-column align-items-center" v-if="!getToken">
    <br /><br />
    <h3 class="text-center">Vous n'êtes pas connecté</h3>
    <br />
    <button
      class="btn btn-follow btn-block black mb-4 mt-2 py-0"
      style="max-width:220px"
      @click="openSignModal"
    >
      Veuillez-vous connecter
    </button>
    <br /><br />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters([
      "getToken"
    ]),
  },
  methods: {
    openSignModal() {
      $("#signModal").modal("show");
    },
  },
};
</script>

<style></style>
