import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import "./lib/firebase";
import router from "./router";
import store from "./store";
import "./assets/scss/index.scss";
import axios from 'axios'
import Vuelidate from 'vuelidate'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

// Global Components
import Header from "./components/headers/Header";
import Footer from "./components/footers/Footer";
import DashboardWrapper from "./components/dashboard/Wrapper";
import DashboardNoProfile from "./components/dashboard/NoProfile";
import DashboardNoOrder from "./components/dashboard/NoOrder";
import Confirmation from "./components/utils/Confirmation";
import Snackbar from "./components/utils/Snackbar.vue";
import LogoutModal from "./components/modals/Logout.vue";
import NotAuthenticated from "./components/utils/NotAuthenticated";
import AlertSuccess from "./components/alerts/Success";
import AlertDanger from "./components/alerts/Danger";
import AlertWarning from "./components/alerts/Warning";
import SignModal from "./components/modals/Sign";
import NotConnectedModal from "./components/modals/NotConnected";
import PageLoader from "./components/utils/PageLoader";
import LoadingIndicator from "./components/utils/LoadingIndicator";

import ArtworkCard from "./components/cards/Artwork";
import ArtistCard from "./components/cards/Artist";
import NoResults from "./components/utils/NoResults";

import CKEditor from "@ckeditor/ckeditor5-vue2";

import { number } from "./filters/number";

const options = {
    position: "top-right",
    timeout: 5000,
};

Vue.use(CKEditor);

Vue.use(Vuelidate);

Vue.use(Toast, options);

Vue.filter("number", number);

Vue.config.productionTip = false;

Vue.component("main-header", Header);
Vue.component("main-footer", Footer);
Vue.component("dashboard-wrapper", DashboardWrapper);
Vue.component("dashboard-noprofile", DashboardNoProfile);
Vue.component("dashboard-noorder", DashboardNoOrder);
Vue.component("Confirmation", Confirmation);
Vue.component("snackbar", Snackbar);
Vue.component("not-authenticated", NotAuthenticated);
Vue.component("alert-success", AlertSuccess);
Vue.component("alert-danger", AlertDanger);
Vue.component("alert-warning", AlertWarning);
Vue.component("logout-modal", LogoutModal);
Vue.component("sign-modal", SignModal);
Vue.component("not-connected-modal", NotConnectedModal);
Vue.component("page-loader", PageLoader);
Vue.component("loading-indicator", LoadingIndicator);
Vue.component("artwork-card", ArtworkCard);
Vue.component("artist-card", ArtistCard);
Vue.component("no-results", NoResults);

axios.defaults.headers.common = {
    // 'Access-Control-Allow-Origin': 'https://api.smsfactor.com',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Authorization': 'Token a9dcda868c0adkjdkeej4250ae0a7b31d15f6525'
};

new Vue({
    router,
    store,
    render: function(h) {
        return h(App);
    },
}).$mount("#app");