<template>
  <!-- Sign-in / Sign-up Modal -->
  <div v-show="!getToken" id="signModal" aria-hidden="true" aria-labelledby="signModalLabel" class="modal fade" data-backdrop="static" data-keyboard="false" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content rounded-0 px-5-md px-8-lg">
        <div class="modal-header pt-8 border-bottom-0">
          <ul class="_flex h4 bold">
            <li>
              <a :class="{'artists_follow_link artists_follow_link_active': isSignup, 'text-muted': isLogin,}"
                  class="mr-3 no-hover c-pointer" @click="isSignup = true">
                Inscription
              </a>
            </li>
            <li>
              <a :class="{'artists_follow_link artists_follow_link_active': isLogin, 'text-muted': isSignup,}"
                  class="mx-3 no-hover c-pointer" @click="isLogin = true">
                Connexion
              </a>
            </li>
          </ul>
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <i class="icon-exit" style="font-size:14px;"></i>
          </button>
        </div>
        <div class="modal-body">
          <!-- SIGN UP -->
          <fieldset v-show="isSignup" :disabled="signup.loading">
            <div class="medium">DÉCOUVREZ ET ACHETEZ L’ART QUE VOUS AIMEZ</div>
            <p class="">Nos experts dénichent pour vous les meilleurs artistes contemporains</p>
            <!--  -->
            <alert-danger v-if="error" :message="error"/>

            <template v-if="!signup.type_user">
              <h6 class="mb-5">Choisir le type de compte :</h6>
              <div class="form-group login-group">
                <label>S'inscrire comme</label>
                <select v-model="signup.type_user" class="form-control rounded-0">
                  <option value="">Choisir</option>
                  <option value="simple">Utilisateur</option>
                  <option value="artist">Artiste</option>
                </select>
              </div>
            </template>
            <!--  -->
            <template v-if="signup.type_user">
              <div class="d-flex align-items-center c-pointer no-hover text-muted mb-5">
                <a class="mr-5" @click="signup.type_user = ''">
                  <i class="fal fa-arrow-left mr-2"></i>Choisir
                </a>
                <h6 class="font-weight-bold my-0">{{ typeUtilisateur }}</h6>
              </div>
              <div class="form-group login-group mb-0">
                <label>Email</label>
                <input v-model.trim="signup.email" class="form-control rounded-0" required type="email"/>
              </div>
              <div class="form-group login-group mb-0">
                <label for="signup_email">Mot de passe</label>
                <input v-model.trim="signup.password" id="signup_email" :type="showPassword ? 'text' : 'password'" class="form-control rounded-0" required/>
                <a class="showPassword" @click="showPassword = !showPassword">{{ showPassword ? "Cacher" : "Voir" }}</a>
              </div>
              <button :disabled="signup.loading" class="btn btn-follow btn-block black mb-4 mt-2 py-0" @click="onSignupWithEmail">
                <span v-if="!signup.loading">Créer un compte</span>
                <i v-else class="far fa-spinner fa-pulse title"></i>
              </button>
<!--              <div class="letter-separator"><span>OU</span></div>
              <div class="row no-gutters">
                <div class="col-12 col-md-6">
                  <button class="btn btn-login" @click="onSignupWithGoogle"><img alt="" src="../../assets/images/google.webp"/> Google </button>
                </div>
                <div class="col-12 col-md-6">
                  <button class="btn btn-login" @click="onSignupWithFacebook"> <i class="fab fa-facebook"></i> Facebook</button>
                </div>
              </div>
              <p class="caption italic">En vous inscrivant, vous acceptez nos <a class="underline" href="">termes et conditions</a> ansi que notre <a class="underline" href="">politique de confidentialité et cookies</a></p>-->
            </template>
          </fieldset>
          <!-- LOG IN -->
          <fieldset v-show="isLogin" :disabled="login.loading">
            <alert-danger v-if="error" :message="error"/>
            <div class="form-group login-group mt-3 mb-0">
              <label for="loginEmail">Email</label>
              <input v-model.trim="login.email" id="loginEmail" class="form-control rounded-0" required type="email"/>
            </div>
            <div class="form-group login-group mb-0">
              <label>Mot de passe</label>
              <input v-model.trim="login.password" :type="showPassword ? 'text' : 'password'" class="form-control rounded-0" required/>
              <a class="showPassword" @click="showPassword = !showPassword">{{ showPassword ? "Cacher" : "Voir" }}</a>
            </div>
            <button :disabled="login.loading || !login.email.length || login.password.length < 5"
                class="btn btn-follow btn-block black mb-4 mt-2 py-0" @click="onLoginWithEmail">
              <span v-if="!login.loading">Se connecter</span>
              <i v-else class="far fa-spinner fa-pulse title"></i>
            </button>
<!--            <div class="letter-separator"><span>OU</span></div>
            <div class="row no-gutters">
              <div class="col-12 col-md-6">
                <button class="btn btn-login" @click="onLoginWithGoogle">
                  <img alt="" src="../../assets/images/google.webp"/>
                  Google
                </button>
              </div>
              <div class="col-12 col-md-6">
                <button class="btn btn-login" @click="onLoginWithFacebook">
                  <i class="fab fa-facebook"></i>
                  Facebook
                </button>
              </div>
            </div>
            <br/>-->
          </fieldset>
        </div>
      </div>
    </div>
  </div>
  <!-- Sign-in / Sign-up Modal -->
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import { FORM_SIGN_IN_SIGN_UP } from '../../validations/formSignInSignUp'

export default {
  data: () => ({
    login: {
      email: "",
      password: "",
      loading: false,
      table: "connection"
    },
    signup: {
      email: "",
      password: "",
      loading: false,
      type_user: "",
      table: "inscription"
    },
    isLogin: false,
    isSignup: true,
    localError: null,
    showPassword: false,
  }),
  computed: {
    ...mapGetters([
      "getToken",
      "error", 
      "errors",
    ]),
    typeUtilisateur() {
      switch (this.signup.type_user) {
        case "simple":
          return "Compte Utilisateur";
        case "artist":
          return "Compte Artiste";
        default:
          return "";
      }
    },
  },
  watch: {
    error(value) {
      if (value) {
        this.login.loading = false;
        this.signup.loading = false;
      }
    },
    isLogin(value) {
      if (value == true) {
        this.isSignup = false;
      }
    },
    isSignup(value) {
      if (value == true) {
        this.isLogin = false;
      }
    },
    getToken(value) {
      if (value) {
        $("#signModal").modal("hide");
      }
    },
  },
  validations: FORM_SIGN_IN_SIGN_UP,
  methods: {
    ...mapMutations([]),
    ...mapActions([
      'axiosPostData',
      'axiosUtilisateurAuth',
    ]),
    onLoginWithEmail() {
      this.signup.loading = true;
      this.axiosUtilisateurAuth(this.login)
          .then( response => {
            if(response.status === 200){
              $("#signModal").modal("hide");
              this.$toast.success(response.data.message, {
                icon: "fas fa-2x fa-check-circle",
              });
            }else {
              this.$toast.error(response.data.message, {
                icon: "fas fa-2x fa-times-circle",
              });
            }
            this.signup.loading = false;
          })
          .catch()
    },
    onSignupWithEmail() {
      this.signup.loading = true;
      this.axiosPostData(this.signup)
          .then( response => {
            if(response.status === 200){
              $("#signModal").modal("hide");
              this.$toast.success(response.data.message, {
                icon: "fas fa-2x fa-check-circle",
              });
            }else {
              this.$toast.error(response.data.message, {
                icon: "fas fa-2x fa-times-circle",
              });
            }
            this.signup.loading = false;
          })
          .catch()
    },
/*
    async onLoginWithGoogle() {
      this.login.loading = true;
      return this.$store
          .dispatch("loginWithGoogle")
          .then((res) => {
            if (res.success) {
              $("#signModal").modal("hide");
            }
            this.login.loading = false;
          })
          .catch(() => {
            this.login.loading = false;
          });
    },
    async onLoginWithFacebook() {
      this.login.loading = true;
      return this.$store
          .dispatch("loginWithFacebook")
          .then((res) => {
            if (res.success) {
              $("#signModal").modal("hide");
            }
            this.login.loading = false;
          })
          .catch(() => {
            this.login.loading = false;
          });
    },
    async onSignupWithGoogle() {
      this.signup.loading = true;
      return this.$store
          .dispatch("signupWithGoogle", {role: this.signup.role})
          .then((res) => {
            if (res.success) {
              $("#signModal").modal("hide");
            }
            this.signup.loading = false;
          })
          .catch(() => {
            this.signup.loading = false;
          });
    },
    async onSignupWithFacebook() {
      this.signup.loading = true;
      return this.$store
          .dispatch("signupWithFacebook", {role: this.signup.role})
          .then((res) => {
            if (res.success) {
              $("#signModal").modal("hide");
            }
            this.signup.loading = false;
          })
          .catch(() => {
            this.signup.loading = false;
          });
    },
*/
  },
};
</script>

<style></style>
