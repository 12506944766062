import { supabase } from "../../lib/supabase";
import { handleUserNotAuth, handleError } from "../errors";

const FAVORITE_ARTISTS_DB = "favorite_artists";
const FAVORITE_ARTWORKS_DB = "favorite_artworks";

const state = {
  favorite_artists: [],
  favorite_artists_count: 0,
  favorite_artworks: [],
  favorite_artworks_count: 0,
};

const getters = {
  favorite_artists: (state) => state.favorite_artists,
  favorite_artworks: (state) => state.favorite_artworks,
  favorite_artists_count: (state) => state.favorite_artists_count,
  favorite_artworks_count: (state) => state.favorite_artworks_count,
};

const mutations = {
  SET_FAVORITE_ARTISTS(state, payload) {
    return (state.favorite_artists = payload);
  },
  SET_FAVORITE_ARTWORKS(state, payload) {
    return (state.favorite_artworks = payload);
  },
  SET_FAVORITE_ARTISTS_COUNT(state, payload) {
    return (state.favorite_artists_count = payload);
  },
  SET_FAVORITE_ARTWORKS_COUNT(state, payload) {
    return (state.favorite_artworks_count = payload);
  },
};

const actions = {
  async addArtistToFavorites({ commit, getters }, payload) {
    const isLoggedIn = getters.isLoggedIn;

    if (!isLoggedIn) handleUserNotAuth();

    const user_id = getters.user_id;

    const { data, error } = await supabase
      .from(FAVORITE_ARTISTS_DB)
      .insert([
        {
          id: `${payload}_${user_id}`,
          artist_id: payload.id,
          user_id,
        },
      ])
      .single();

    if (error) {
      handleError();
    }

    commit(
      "SET_ALERT",
      `Vous suivez maintenant ${payload.firstname || ""} ${payload.lastname ||
        ""}`
    );

    return { success: true, data };
  },
  async addArtworkToFavorites({ commit, getters }, payload) {
    const isLoggedIn = getters.isLoggedIn;

    if (!isLoggedIn) handleUserNotAuth();

    const user_id = getters.user_id;

    const { data, error } = await supabase.from(FAVORITE_ARTWORKS_DB).insert([
      {
        id: `${payload}_${user_id}`,
        artwork_id: payload,
        user_id,
      },
    ]);

    if (error) {
      handleError();
    }

    return { success: true, data };
  },
  async removeArtworkToFavorites({ commit, getters }, artwork_id) {
    const isLoggedIn = getters.isLoggedIn;

    if (!isLoggedIn) handleUserNotAuth();

    const user_id = getters.user_id;

    const { data, error } = await supabase
      .from(FAVORITE_ARTWORKS_DB)
      .delete()
      .match({ artwork_id, user_id });

    if (error) {
      handleError();
    }

    console.log(data);

    return { success: true, data };
  },
  async removeArtistToFavorites({ commit, getters }, artist_id) {
    const isLoggedIn = getters.isLoggedIn;

    if (!isLoggedIn) handleUserNotAuth();

    const user_id = getters.user_id;

    const { data, error } = await supabase
      .from(FAVORITE_ARTISTS_DB)
      .delete()
      .match({ artist_id, user_id });

    if (error) {
      handleError();
    }

    console.log(data);

    return { success: true, data };
  },
  async fetchFavoriteArtists({ commit, getters }) {
    const isLoggedIn = getters.isLoggedIn;

    if (!isLoggedIn) return;

    const { data, error, count } = await supabase
      .from(FAVORITE_ARTISTS_DB)
      .select(
        `
        *, 
        artist:artist_id ( * )
      `,
        { count: "exact" }
      )
      .eq("user_id", getters.user_id);

    if (error) {
      console.log(error);
    }

    console.log(data);
    commit("SET_FAVORITE_ARTISTS", data);
    commit("SET_FAVORITE_ARTISTS_COUNT", count);
  },
  async fetchFavoriteArtworks({ commit, getters }) {
    const isLoggedIn = getters.isLoggedIn;

    if (!isLoggedIn) return;

    const { data, error, count } = await supabase
      .from(FAVORITE_ARTWORKS_DB)
      .select(
        `
        *, 
        artwork:artwork_id ( * )
      `,
        { count: "exact" }
      )
      .eq("user_id", getters.user_id);

    if (error) {
      console.log(error);
    }

    console.log(data);
    commit("SET_FAVORITE_ARTWORKS", data);
    commit("SET_FAVORITE_ARTWORKS_COUNT", count);
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
