<template>
  <div class="row no-gutters dashboard">
    <dashboard-sidenav />
    <div class="col-12 col-md-9 offset-md-3 dashboard-space">
      <!-- Dashboard Header -->
      <dashboard-header />
      <!-- Body -->
      <section class="dashboard-content">
        <slot />
      </section>
    </div>
  </div>
</template>

<script>
import DashboardHeader from "./Header";
import DashboardSidenav from "./Sidenav";

export default {
  components: {
    DashboardHeader,
    DashboardSidenav,
  },
};
</script>

<style lang="scss" scoped>
@import url(../../assets/scss/dashboard.scss);
</style>
