<template>
  <!-- Header -->
  <section class="bg-dashboard px-5 py-3 _flex _between _wrap border-bottom">
    <div>
      <div class="medium subheading">
        Bienvenu(e), <span class="text-uppercase" v-if="getToken">{{ fullname }}</span>
      </div>
      <div class="" style="color:#888">
        Une question ? Consultez notre
        <router-link to="/aide" class="underline">centre d'aide</router-link>
      </div>
    </div>
    <div class="medium" v-if="getUserData && getUserData.userData && (getUserData.userData.type_user === 'artist')">
      <router-link :to="`/artiste/${getToken}`">Voir ma galerie dans le site</router-link>
      <span class="normal mx-1" style="color:#888">|</span> <router-link to="/aide">Aide</router-link>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters([
      "profile",
      "isLoggedIn",
      "getToken",
      "getUserData",
    ]),
    fullname() {
      if (!this.getUserData && !(this.getUserData.userData.type_user === 'artist')) {
        return ""
      }
      return `${this.getUserData.userData.last_name} ${this.getUserData.userData.middle_name} ${this.getUserData.userData.first_name}`
    }
  },
  watch: {
    isLoggedIn(value) {
      if (!value) {
        return this.$router.push("/");
      }
    },
  },
};
</script>

<style></style>
