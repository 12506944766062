import { supabase } from "../../lib/supabase";
import { handleUserNotAuth, handleError } from "../errors";

const CART_DB = "carts";

const state = {
  cart: [],
  cart_count: 0,
  totalPrices: 0,
};

const getters = {
  cart: (state) => state.cart,
  cart_count: (state) => state.cart_count,
  totalPrices: (state) => {
    let price = 0;
    if (!state.cart.length) return price;

    state.cart.map((item) => {
      price += item.total_prices;
    });

    return price;
  },
};

const mutations = {
  SET_CART(state, payload) {
    return (state.cart = payload);
  },
  SET_CART_COUNT(state, payload) {
    return (state.cart_count = payload);
  },
};

const actions = {
  async fetchCart({ commit, getters }) {
    const isLoggedIn = getters.isLoggedIn;

    if (!isLoggedIn) return;

    const user_id = getters.user_id;

    const { data, error, count } = await supabase
      .from(CART_DB)
      .select(
        `
        *, 
        artwork:artwork_id ( *, artist:artist_id (firstname, lastname, middlename) )
      `,
        { count: "exact" }
      )
      .eq("user_id", user_id);

    if (error) {
      console.log(error);
      handleError();
    }

    console.log(data);

    commit("SET_CART", data);
    commit("SET_CART_COUNT", count);
  },
  async addToCart({ commit, getters }, payload) {
    const isLoggedIn = getters.isLoggedIn;

    if (!isLoggedIn) {
      return handleUserNotAuth();
    }

    const user_id = getters.user_id;

    let response;

    const artwork_id = payload.id;
    const price = payload.price;

    var newCartItem;

    const { data: existingItem } = await supabase
      .from(CART_DB)
      .select()
      .match({ user_id, artwork_id })
      .single();

    if (existingItem) {
      let oldQty = existingItem.quantity;
      const newQty = payload.qty || oldQty++;
      const newTotalPrices = price * newQty;

      newCartItem = {
        ...existingItem,
        quantity: newQty,
        total_prices: newTotalPrices,
        updated_at: Date.now().toString(),
      };

      if (newQty > payload.artwork.item_totals) {
        return alert("La quantité dépasse le nombre d'articles disponibles!");
      }

      response = await supabase
        .from(CART_DB)
        .update(newCartItem)
        .match({ id: existingItem.id });
    } else {
      const newQty = payload.qty || 1;
      const total_prices = price * newQty;

      if (newQty > payload.artwork.item_totals) {
        return alert("La quantité dépasse le nombre d'articles disponibles!");
      }

      newCartItem = { quantity: newQty, total_prices, artwork_id, user_id };

      response = await supabase.from(CART_DB).insert([newCartItem]);
    }

    const { data, error } = response;

    if (error) {
      handleError();
    }

    console.log(data[0]);

    commit("SET_ALERT", `Votre article a été ajouté au panier`);

    return { success: true, data: data[0] };
  },
  async removeToCart({ commit, getters }, artwork_id) {
    const isLoggedIn = getters.isLoggedIn;

    if (!isLoggedIn) handleUserNotAuth();

    const user_id = getters.user_id;

    const { data, error } = await supabase
      .from(CART_DB)
      .delete()
      .match({ artwork_id, user_id });

    if (error) {
      handleError();
    }

    console.log(data);
  },
  async cartIncrement({ dispatch, getters }, payload) {
    const isLoggedIn = getters.isLoggedIn;

    if (!isLoggedIn) handleUserNotAuth();

    const user_id = getters.user_id;

    let newQty = payload.quantity + 1;

    if (newQty > payload.artwork.item_totals) {
      return alert("La quantité dépasse le nombre d'articles disponibles!");
    }

    const total_prices = payload.artwork.price * newQty;

    const { data, error } = await supabase
      .from(CART_DB)
      .update({ quantity: newQty, total_prices })
      .match({ id: payload.id, user_id });

    if (error) {
      handleError();
    }

    console.log(data);

    dispatch("fetchCart");

    return { success: true };
  },
  async cartDecrement({ dispatch, getters }, payload) {
    const isLoggedIn = getters.isLoggedIn;

    if (!isLoggedIn) handleUserNotAuth();

    const user_id = getters.user_id;

    let newQty = payload.quantity - 1;

    if (newQty < 1) {
      return alert("La quantity ne peut être au-dessous de 1!");
    }

    const total_prices = payload.artwork.price * newQty;

    const { data, error } = await supabase
      .from(CART_DB)
      .update({ quantity: newQty, total_prices })
      .match({ id: payload.id, user_id });

    if (error) {
      handleError();
    }

    console.log(data);

    dispatch("fetchCart");

    return { success: true };
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
