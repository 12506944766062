import { supabase } from "../../lib/supabase";
import {
  auth,
  storage,
  GOOGLE_PROVIDER,
  FACEBOOK_PROVIDER,
} from "../../lib/firebase";
import {
  handleError,
  handleErrorArg,
  handleAuthError,
  handleSignError,
  handleUserNotAuth,
  handleUserAlreadyAuth,
} from "../errors";
import validator from "validator";

const ARTISTS_DB = "artists";

const state = {
  user: null,
  profile: null,
  isLoggedIn: false,
};

const getters = {
  user: (state) => state.user,
  user_id: (state) => (state.user ? state.user.id : null),
  profile: (state) => state.profile,
  isArtist: (state) => {
    if (state.isLoggedIn && state.user && state.user.is_artist) return true;
    return false;
  },
  isLoggedIn: (state) => state.isLoggedIn,
};

const mutations = {
  SET_USER(state, payload) {
    state.user = payload;
  },
  SET_IS_LOGGED_IN(state, payload) {
    state.isLoggedIn = payload;
  },
  SET_MY_PROFILE_ARTIST(state, payload) {
    state.profile = payload;
  },
};

const actions = {
  /**
   * Handles user auto Sign In
   */
  autoSignIn: ({ commit }, uid) => {
    commit("SET_USER", { id: uid });
  },
  /**
   * Fetch User Profile
   */
  async fetchProfile({ commit, getters }) {
    if (!getters.isLoggedIn) return;

    const { data, error } = await supabase
      .from("profiles")
      .select()
      .eq("id", getters.user_id)
      .single();

    if (error) {
      console.log(error.message);
    }

    if (data) {
      commit("SET_USER", { ...getters.user, ...data });
    }
  },

  /**
   * Handles update profile
   */
  async updateProfile({ commit, getters }, payload) {
    const user_id = getters.user_id;

    let imageResponse;
    const {
      firstname,
      lastname,
      middlename,
      country,
      city,
      image,
      phone_number,
    } = payload;

    if (!getters.isLoggedIn) handleUserNotAuth();

    const profileInfo = {
      firstname,
      lastname,
      middlename,
      country,
      city,
      phone_number,
    };

    if (image) {
      let uploadTask = storage.ref(`avatars/${getters.user_id}.png`).put(image);

      imageResponse = await new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          () => {}, // snapshot
          () => {}, // error
          () => {
            return uploadTask.snapshot.ref
              .getDownloadURL()
              .then((downloadURL) => resolve(downloadURL))
              .catch((e) => reject(e));
          }
        );
      });

      if (imageResponse) {
        profileInfo.avatar_url = imageResponse;
      }
    }

    const { data, error } = await supabase
      .from("profiles")
      .update({ ...profileInfo })
      .match({ id: user_id });

    if (error) {
      console.log(error);
      handleError();
    }

    if (data) {
      console.log(data[0]);
      commit("SET_USER", { ...getters.user, ...data[0] });
    }

    return { success: true, data };
  },

  /**
   * Handles Profile's Artist
   */
  async fetchMyProfileArtist({ commit, getters }) {
    if (!getters.isLoggedIn) return;

    const user_id = getters.user_id;

    const { data, error } = await supabase
      .from(ARTISTS_DB)
      .select("*")
      .eq("id", user_id)
      .single();

    if (error) {
      console.log(error);
    }

    commit("SET_MY_PROFILE_ARTIST", data);
  },

  /**
   * Handles user auto Sign In
   */
  // autoSignIn: ({ commit }, uid) => {
  //   commit("SET_USER", { id: uid });
  // },

  /**
   * Handles Login With Email
   */
  async loginWithEmail({ commit, getters }, payload) {
    if (getters.isLoggedIn) handleUserAlreadyAuth();

    if (!validator.isEmail(payload.email)) {
      return handleErrorArg("L'adresse email est invalide!");
    }
    if (
      validator.isEmpty(payload.password) ||
      !validator.isLength(payload.password, { min: 5 })
    ) {
      return handleErrorArg(
        "Le mot de passe doit comporter au-moins 5 caractères!"
      );
    }

    return auth
      .signInWithEmailAndPassword(payload.email, payload.password)
      .then((userInfo) => {
        const user = userInfo.user;
        commit("SET_USER", { id: user.uid, provider: "email" });
        return { success: true };
      })
      .catch((error) => handleAuthError(error));
  },

  /**
   * Handles Login With Google
   */
  async loginWithGoogle({ commit, getters }) {
    if (getters.isLoggedIn) handleUserAlreadyAuth();

    return auth
      .signInWithPopup(GOOGLE_PROVIDER)
      .then((res) => {
        const user = res.user;
        commit("SET_USER", { id: user.uid, provider: "google" });
        return { success: true };
      })
      .catch((error) => handleAuthError(error));
  },

  /**
   * Handles Login With Facebook
   */
  async loginWithFacebook({ commit, getters }) {
    if (getters.isLoggedIn) handleUserAlreadyAuth();

    return auth
      .signInWithPopup(FACEBOOK_PROVIDER)
      .then((res) => {
        const user = res.user;
        commit("SET_USER", { id: user.uid, provider: "facebook" });
        return { success: true };
      })
      .catch((error) => handleAuthError(error));
  },

  /**
   * Handles Signup With Email
   */
  async signupWithEmail({ commit, getters }, payload) {
    const { email, password, role } = payload;

    if (getters.isLoggedIn) handleUserAlreadyAuth();

    if (!validator.isEmail(payload.email)) {
      return handleErrorArg("L'adresse email est invalide!");
    }
    if (
      validator.isEmpty(payload.password) ||
      !validator.isLength(payload.password, { min: 5 })
    ) {
      return handleErrorArg(
        "Le mot de passe doit comporter au-moins 5 caractères!"
      );
    }

    try {
      const { user } = await auth.createUserWithEmailAndPassword(
        email,
        password
      );
      if (!user) {
        return handleError();
      }

      const { error, data } = await supabase.from("profiles").insert([
        {
          id: user.uid,
          username: user.displayName,
          avatar_url: user.photoURL,
          email: user.email,
          phone_number: user.phoneNumber,
          email_verified: user.emailVerified,
          provider: "email",
        },
      ]);

      if (error) {
        console.log(error.message);
        handleError();
      }

      await auth.currentUser.sendEmailVerification({
        url: `${process.env.BASE_URL}/verifier-email`,
      });

      commit("SET_USER", null);

      alert(
        "Inscription réussie, un mail de confirmation devrait être envoyé bientôt!"
      );
      return { success: true };
    } catch (error) {
      console.log(error);
      handleSignError(error);
    }
  },

  /**
   * Handles Signup with Google
   */
  async signupWithGoogle({ commit, getters }, payload) {
    const isLoggedIn = getters.isLoggedIn;

    if (isLoggedIn) handleUserAlreadyAuth();

    try {
      const { user } = await auth.signInWithPopup(GOOGLE_PROVIDER);

      if (!user) {
        return handleError();
      }

      const { error, data } = await supabase.from("profiles").insert([
        {
          id: user.uid,
          username: user.displayName,
          email: user.email,
          avatar_url: user.photoURL,
          phone_number: user.phoneNumber,
          is_artist: payload.role == "artist" ? true : false,
          email_verified: false,
          provider: "google",
        },
      ]);

      if (error) {
        console.log(error.message);
        handleError();
      }

      commit("SET_USER", { ...getters.user, ...data });

      return { success: true };
    } catch (error) {
      handleSignError(error);
    }
  },

  /**
   * Handles Signup with Facebook
   */
  async signupWithFacebook({ commit, getters }, payload) {
    if (getters.isLoggedIn) handleUserAlreadyAuth();

    try {
      const { user } = await auth.signInWithPopup(FACEBOOK_PROVIDER);

      if (!user) {
        return handleError();
      }

      const { error, data } = await supabase.from("profiles").insert([
        {
          id: user.uid,
          username: user.displayName,
          avatar_url: user.photoURL,
          email: user.email,
          phone_number: user.phoneNumber,
          is_artist: payload.role == "artist" ? true : false,
          email_verified: false,
          provider: "facebook",
        },
      ]);

      if (error) {
        console.log(error.message);
        handleError();
      }

      commit("SET_USER", { ...getters.user, ...data });

      return { success: true };
    } catch (error) {
      handleSignError(error);
    }
  },
  /**
   * Handles Update User Email
   */
  async updateUserEmail({ commit }, email) {
    var user = auth.currentUser;
    if (!user.uid) return;

    return user
      .updateEmail(email)
      .then(async () => {
        await supabase
          .from("profiles")
          .update({ email })
          .match({ id: user.uid });

        alert("Votre adresse email a été mise à jour");
        commit("SET_USER", { email });
        return { success: true };
      })
      .catch((error) => {
        console.log(error);
        handleError();
      });
  },

  /**
   * Handles Update User Password
   */
  async updateUserPassword({ commit }, password) {
    var user = auth.currentUser;
    if (!user.uid) return;

    return user
      .updatePassword(password)
      .then(() => {
        alert("Votre mot de passe a été mise à jour");
        return { success: true };
      })
      .catch((error) => {
        console.log(error);
        handleError();
      });
  },

  /**
   * Handles Password Forgot
   */
  async passwordForgot({ commit, getters }, emailAddress) {
    var user = auth.currentUser;
    if (!user.uid) return;

    return auth
      .sendPasswordResetEmail(emailAddress)
      .then(function() {
        // Email sent.
        alert(
          "Un mail a été envoyé dans votre boîte. Veuillez le confirmer pour réinitialiser votre mot de passe"
        );
        return { success: true };
      })
      .catch((error) => {
        console.log(error);
        handleError();
      });
  },

  /**
   * Handles Delete User
   */
  async deteleUser({ commit, getters, dispatch }) {
    var user = auth.currentUser;
    if (!user.uid) return;

    try {
      return supabase
        .from("profiles")
        .delete()
        .match({ id: user.uid })
        .then(() => {
          return user.delete();
        })
        .then(function() {
          // User deleted.
          alert("Votre compte a été supprimé");
          dispatch("logout");
          return { success: true };
        });
    } catch (error) {
      console.log(error);
      handleError();
    }
  },

  /**
   * Handles Deactive User
   */
  async deactivateUser({ commit, dispatch }) {
    var user = auth.currentUser;
    if (!user.uid) return;

    const { data, error } = await supabase
      .from("profiles")
      .update({ enabled: false })
      .match({ id: user.uid });

    if (error) {
      console.log(error);
      return handleError();
    }

    alert("Votre compte a été désactivé");
    console.log("User account deactivation successfully");
    dispatch("logout");
  },

  /**
   * Handles Logout
   */
  async logout({ commit }) {
    var user = auth.currentUser;
    if (!user.uid) return;

    return auth
      .signOut()
      .then(() => {
        commit("SET_USER", null);
        commit("SET_IS_LOGGED_IN", false);
        commit("SET_FAVORITE_ARTWORKS", {});
        commit("SET_FAVORITE_ARTISTS", {});
        commit("SET_MY_PROFILE_ARTIST", null);
      })
      .catch((error) => {
        console.log(error);
        handleError();
      });
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
