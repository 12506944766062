<template>
  <!-- Sign Modal -->
  <div class="modal fade" id="logoutModal" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="logoutModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content rounded-0 px-5-md px-8-lg">
        <div class="modal-header pt-8 border-bottom-0">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="icon-exit" style="font-size:14px;"></i>
          </button>
        </div>
        <div class="modal-body">
          <h5 class="text-center">Voulez-vous vous déconnecter à votre compte?</h5>
          <br />
          <div class="d-flex">
            <button class="btn btn-follow btn-block text-danger mb-4 mt-2 py-0" data-dismiss="modal" type="button">
              Annuler
            </button>
            <button class="btn btn-follow btn-block black mb-4 mt-2 py-0" @click="onLogout">
              Confirmer
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  methods: {
    ...mapMutations([]),
    ...mapActions([
      'axiosUtilisateurDeconnection',
    ]),

    onLogout() {
      this.axiosUtilisateurDeconnection()
      if(!this.getToken){
        $('#logoutModal').modal('hide')
        this.$toast.success("Déconnection effectuée avec succès", {
          icon: "fas fa-2x fa-check-circle",
        });
      }
    }
  },
  computed: {
    ...mapGetters([
      "getToken"
    ]),
  },
}
</script>
